import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OptionalParamsConsumer } from "src/app/abstract/OptionalParamsConsumer/OptionalParamsConsumer.component";
import { setupTokenForManageUserPortal } from './helpers/PublicUrlsEnabled.helper';

@Injectable()
export class PublicUrlsEnabledInterceptor 
extends OptionalParamsConsumer
implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = this.optionalParams.token
    if(!token){
        return next.handle(req);
    }
    token = setupTokenForManageUserPortal(token);
    const modifiedReq = req.clone({
      headers: req.headers.set('ConnectToken', token)
    });
    return next.handle(modifiedReq); 
  }
}