import { Directive, HostListener, ElementRef } from "@angular/core";
import { FormGroupDirective } from "@angular/forms";
import { fromEvent } from "rxjs";
import { debounceTime, take } from "rxjs/operators";

@Directive({
    selector: "[zumInvalidControlScrollDirective]",
})
export class InvalidControlScrollDirective {

    constructor(
        private el: ElementRef,
        private formGroupDir: FormGroupDirective
    ) { }

    @HostListener("submit")
    onSubmit() {
        if (this.formGroupDir.control.invalid) {
            this.scrollToFirstInvalidControl();
        }
    }

    private scrollToFirstInvalidControl() {
        let firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector("form input.ng-invalid");

        if(!firstInvalidControl) {
            firstInvalidControl = this.el.nativeElement.querySelector("form mat-select.ng-invalid");
        }

        if(!firstInvalidControl) {
            return;
        }

        let scrollSelector = document.querySelector("#scroll-page");

        if(!scrollSelector) {
            this.scroll(window, firstInvalidControl);
            return;
        }

        this.scroll(scrollSelector, firstInvalidControl);
    }

    private getTopOffset(controlEl: HTMLElement): number {
        const labelOffset = 50;
        return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
    }

    private scroll(element, firstInvalidControl) {
        element.scroll({
            top: this.getTopOffset(firstInvalidControl),
            left: 0,
            behavior: "smooth",
        });

        fromEvent(element, "scroll")
            .pipe(debounceTime(100), take(1))
            .subscribe(() => firstInvalidControl.focus());
    }
}
