type ParamDigestType = "string" | "number" | "bigint" | "boolean" | "symbol" | "undefined" | "object" | "function";



export const digestValue = (
    value: unknown,
    digestType: ParamDigestType
): string | boolean | number | undefined => {
    switch (digestType) {
        case "boolean":
            return parseToBoolean(value);
        case "number":
            return parseToNumber(value);
        case "string":
        default:
            return parseToString(value);
    }
};

export const parseToBoolean = (value: unknown): boolean | undefined => {
    if (value === null || value === undefined) return undefined;
    if (typeof value === "boolean") return value;
    if (typeof value === "string") {
        const lowerValue = value.toLowerCase().trim();
        if (lowerValue === "true") return true;
        if (lowerValue === "false") return false;
    }
    return Boolean(value);
};

export const parseToNumber = (value: unknown): number | undefined => {
    if (value === null || value === undefined) return undefined;
    const parsedValue = Number(value);
    return isNaN(parsedValue) ? undefined : parsedValue;
};

export const parseToString = (value: unknown): string | undefined => {
    if (value === null || value === undefined) return undefined;
    return String(value);
};
