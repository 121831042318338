import { Pipe, PipeTransform, Inject, LOCALE_ID } from "@angular/core";
import {ZumDatePipe} from "./zumDate.pipe";

@Pipe({
    name: "zumDateWithoutTZ",
})
export class ZumDateWithoutTZPipe implements PipeTransform {
    datePipe: ZumDatePipe;

    constructor(@Inject(LOCALE_ID) private locale: string) {
        this.datePipe = new ZumDatePipe(locale);
    }
    transform(value: string | Date, format: string = "yyyy-MM-dd"): string {
        return this.datePipe.transform(value, format, null);
    }
}