import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class PageTitleService {
    private pageTitleSubject: BehaviorSubject<string>;
    public pageTitle: Observable<string>;

    constructor
    (
    )
    {
        this.pageTitleSubject = new BehaviorSubject<string>('');
        this.pageTitle = this.pageTitleSubject.asObservable();
    }

    updatePageTitle(title: string) {
        this.pageTitleSubject.next(title);
    }
}