import { ElementRef, Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { CustomAutocompleteItem } from '../models/CustomAutocompleteItem';
import { InvoiceItemOutputDTO, InvoiceOutputDTO } from '../models/Invoice';
import { ProductItemInput } from '../models/Product';
import { ProductBillingPeriod, ProductPriceFrequency, ProductPriceOutputDTO, ProductPriceType } from '../models/ProductPrice';
import { SubscriptionOutPutDTO } from '../models/Subscription';


@Injectable()
export class GlobalHelper {

  constructor(private translate: TranslateService){}

  formatOnlyDate(dateString: string) {
    let date = moment.utc(dateString);
    return date.local().format("MM-DD-YYYY");
  }

  formatOnlyDateWithoutUTC(dateString: string) {
      let date = moment(dateString);
      return date.local().format("MM-DD-YYYY");
  }

  calculateProductPriceItemAmount(item: ProductItemInput) {
    if (!item.Id) {
        return this.standardPriceCalculation(item.Quantity, item.Price);
    }
    else{
        if (item.Id && item.ProductPriceType == ProductPriceType.Package) {
            return this.packgePriceCalculation(item.Quantity, item.PerUnit, item.Price);

        }
        if (item.Id && item.ProductPriceType == ProductPriceType.Standard) {
            return this.standardPriceCalculation(item.Quantity, item.Price);
        }
    }
  }

  packgePriceCalculation(quantity, perUnit, price) {
      return (Math.ceil(quantity / perUnit) * price);
  }

  standardPriceCalculation(quantity, price) {
      return (quantity * price);
  }

  getProductName(item: InvoiceItemOutputDTO) : string  {
    if (item.OneOffProductName) {
      return item.OneOffProductName
    } 

    return item.ProductPrice.PlanName ?? item.ProductPrice.Product.Name
  }

  getProductNameForBasicDetails(item: InvoiceItemOutputDTO) : string  {
    if (item.OneOffProductName) {
      return item.OneOffProductName
    }

    return item.ProductPrice.Product.Name
  }

  getSubtitle(item: InvoiceItemOutputDTO) : string  {
    if (item.OneOffProductName) {
      return ""
    }

    return item.ProductPrice.PlanName ?? item.ProductPrice.Product.Description
  }

  getProductDescription(item: InvoiceItemOutputDTO) : string  {
    return item.ProductPrice.Product.Description
  }

  getBillingPeriod(productPrice: ProductPriceOutputDTO) {
        if (productPrice.Frequency === 'OneTime') {
            return;
        }        

        let billingPeriodDescription = "";
        
        switch (productPrice.BillingPeriod) {
            case ProductBillingPeriod.Monthly:
                billingPeriodDescription = this.translate.instant('LABEL.MONTH');
                break;
            case ProductBillingPeriod.Weekly:
                billingPeriodDescription = this.translate.instant('LABEL.WEEK');
                break;
            case ProductBillingPeriod.Biweekly:
                billingPeriodDescription = this.translate.instant('LABEL.TWOWEEKS');
                break;
            case ProductBillingPeriod.Annually:
                billingPeriodDescription = this.translate.instant('LABEL.YEAR');
                break;
        }

        let prefix = "/";

        if(productPrice.ProductPriceType === 'Standard'){
            prefix = this.translate.instant('LABEL.PER') + " ";
        }

        return `${prefix}${billingPeriodDescription}`;
    }

    getCurrentLabel(isAddUser:boolean) {
        if (!isAddUser) {
            return "INVOICE.TEXT.PROCESSINGPAYMENT"
        }
        return "CONNECT.CREATINGUSERPAYMENTPROFILE"
    }

    isInvoiceSubscription(invoice: InvoiceOutputDTO) {
        if (invoice && invoice.Items[0].ProductPrice && invoice.Items[0].ProductPrice.Frequency == ProductPriceFrequency.Recurring && !invoice.SubscriptionId) {
            return true;
        }

        return false;
    }

    getCommonFeeDescription(item: CustomAutocompleteItem){
        let label = "";

        if(!item.Description || item.Description.length === 0){
            return;
        }

        if(item.ClassName == "ShippingRate")
            {
                if(parseInt(item.Description.charAt(0))  > 0 && (parseInt(item.Description.charAt(1)) <= 0 || item.Description.charAt(1) == null)){
                    label =`(At least ${item.Description.charAt(0)} business days)`;
                }
                if(parseInt(item.Description.charAt(0))  > 0 && parseInt(item.Description.charAt(1)) > 0){
                    label =`(Between ${item.Description.charAt(0)}-${item.Description.charAt(1)} business days)`;
                }
                if((parseInt(item.Description.charAt(0)) <= 0 || item.Description.charAt(0) == null) && parseInt(item.Description.charAt(1)) > 0){
                    label =`(At most ${item.Description.charAt(1)} business days)`;
                }
            } else {
                label = item.Description;
            }
    
            return label;
    }

    identifyLabelInvoice(label) {
        switch (label) {
            case 'PastDue':
                return this.translate.instant('INVOICE.PASTDUE');
            case 'Open':
                return this.translate.instant('INVOICE.OPEN');
            case 'Void':
                return this.translate.instant('INVOICE.VOID');
            case 'Uncollectibled':
                return this.translate.instant('INVOICE.UNCOLLECTIBLE');
            case 'Paid':
                return this.translate.instant('INVOICE.PAID');
            default:
                return label;
        }
    }

    toggleHeader(show: boolean) {
        const header = document.querySelector('connect-info-header-v2');
        if (header) {
            header.classList.toggle('d-none', !show);
        }
    }
}