import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class LoadingService {
    private isLoadingSubject: BehaviorSubject<boolean>;
    public isLoading: Observable<boolean>;

    constructor
    (
    )
    {
        this.isLoadingSubject = new BehaviorSubject<boolean>(false);
        this.isLoading = this.isLoadingSubject.asObservable();
    }

    updateIsLoading(isLoading: boolean) {
        this.isLoadingSubject.next(isLoading);
    }
}