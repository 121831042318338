<div class="error-component d-flex w-full justify-content-center align-items-center overflow-y-hidden overflow-x-hidden">
    <div class="error-information-container">
        <app-error-svg-v2></app-error-svg-v2>
        <div class="col-12 text-center mt-3 font-medium text-xl leading-7">{{ errorMessage }}</div>
        <div *ngIf="showButton" class="col-12 text-center pt-1 mt-2">
            <button class="btn btn-outline-secondary w-100" aria-label="retry" (click)="handleButtonClick()"
                    type="submit">
                {{ buttonText }}
            </button>
        </div>
    </div>
</div>