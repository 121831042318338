import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
    name: "zumDate",
})
export class ZumDatePipe implements PipeTransform {
    datePipe: DatePipe;

    constructor(translate: TranslateService) {
        this.datePipe = new DatePipe(translate.currentLang + "-CA");
    }

    convertToDate(value: string): Date {
        const DATETIME_REGEX =
            /^([0-9]{4})-([0-9]{1,2})-([0-9]{1,2})T([0-9]{1,2}):([0-9]{1,2}):([0-9]{1,2}).*$/;
        if (!DATETIME_REGEX.test(value)) {
            return new Date(value);
        }
        const [_, year, month, day, hour, min, sec] =
            value.match(DATETIME_REGEX);
        return new Date(Date.UTC(+year, +month - 1, +day, +hour, +min, +sec));
    }

    verifyOnlyDate(value: string): boolean {
        return /^([0-9]{4})-([0-9]{1,2})-([0-9]{1,2})T00:00:00$/.test(value);
    }

    transform(
        value: string | Date,
        format: string = "yyyy-MM-dd HH:mm:ss",
        timezone: string = "UTC",
        locale: string = "en"
    ): string {
        if (!value) {
            return "";
        }

        if (timezone === "UTC") {
            const zone = new Date()
                .toString()
                .replace(/.*GMT([0-9+-]*).*/, "$1");
            timezone = "UTC" + zone;
        }

        if (typeof value === "string") {
            if (timezone === null || this.verifyOnlyDate(value)) {
                const tempValue = new Date(value);
                value = new Date(tempValue.getUTCFullYear(), tempValue.getUTCMonth(), tempValue.getUTCDate());
            } else {
                value = this.convertToDate(value);
            }
        }

        return this.datePipe.transform(value, format, timezone, locale + "-CA");
    }
}
