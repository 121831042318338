import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogData } from "../connect-wrapper/connect-wrapper.component";

@Component({
  selector: 'pad-dialog',
  templateUrl: 'pad-dialog.component.html',
})
export class PadDialog {

  customerName: string

  constructor(
    public dialogRef: MatDialogRef<PadDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
      this.customerName = data.companyName
    }

  onActionClick(agree): void {
      this.data.agree = agree;
      this.dialogRef.close(this.data);
  }
}