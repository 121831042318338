import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventListenerService } from 'src/app/services/event-listener.service';
import { PageTitleService } from 'src/app/services/page-title.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  constructor(
    private router: Router,
    private pageTitleService: PageTitleService,
    private eventListenerService: EventListenerService
  ) { 

    this.eventListenerService.resize('425px');
  }

  ngOnInit(): void {
    

    this.pageTitleService.updatePageTitle('Loading');
  }

  onClose(): void {

    this.eventListenerService.closePopup();
    return;
  }
}
