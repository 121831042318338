import {Component, Input, OnInit} from "@angular/core";
import {OptionalParamsConsumer} from "../../../../../abstract/OptionalParamsConsumer/OptionalParamsConsumer.component";
import {OptionalQueryParamsService} from "../../../../../services/optional-query-params.service";

@Component({
    selector: "connect-info-header-v2",
    templateUrl: "./connect-info-header.component-v2.html",
    styleUrls: ["./connect-info-header.component-v2.scss"],
    providers: [OptionalQueryParamsService]
})

export class ConnectInfoHeaderComponentV2 extends OptionalParamsConsumer{
    @Input() image: string = null;
}

