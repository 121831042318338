export const environment = {
    production: true,
    apiUrl: "https://api-sandbox.zumrails.com/api",
    aggregationUrl: "https://cdn.aggregation.zumrails.com/sandbox/connector.js",
    testInstitution: true,
    customerAggregation: false,
    aggregationTestCustomerId: '94505e9d-342f-4a57-9365-778dbdd7be34',
    threeDS: "https://api-sandbox.3dsintegrator.com/v2.2",
    validateVersion: true,
};
