<div [ngClass]="{'mb-0' : optionalParams?.reduceSpacing, 'mb-2' : !optionalParams?.reduceSpacing}" class="d-flex mb-md-0">
    <div *ngIf="optionalParams?.removeLogo == false" class="d-flex m-md-4 m-2">
        <div
                id="iframe-logo"
                class="image-container"
                style="background-image: url('{{ image }}'); background-position: center; background-size: 100px; background-repeat:no-repeat;"
        ></div>
    </div>
    <div class="d-flex align-items-center m-md-4 m-2">
        <span class="d-lg-inline d-none h2 bold-text m-0">{{ "CONNECT.CREATEPAYMENTPROFILE" | translate }}</span>
        <span class="d-lg-none d-inline h4 bold-text m-0">{{ "CONNECT.CREATEPAYMENTPROFILE" | translate }}</span>
    </div>
</div>
