import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GlobalModule } from './app.global.module';
import { StylesBehaviorModule } from './components/force-version-two-styles/styles-behavior.module';
import { GlobalHelper } from './helpers/GlobalHelper';
import { ValidatorsHelper } from './helpers/ValidatorsHelper';
import { MaterialModule } from './material/material.module';
import { AmountService } from './pages/connect/connect-wrapper/services/amount.service';
import { PadDialogV2 } from "./pages/connect/pad-dialog/pad-dialog-v2.component";
import { PadDialog } from './pages/connect/pad-dialog/pad-dialog.component';
import { TCTextCaComponent } from "./pages/connect/pad-dialog/text-v2/tc-text-ca.component";
import { TCTextUsComponent } from "./pages/connect/pad-dialog/text-v2/tc-text-us.component";
import { LoadingComponent } from './pages/questrade/loading/loading.component';
import { PublicUrlsEnabledInterceptor } from './publicUrlsEnabledInterceptor';
import { EventListenerService } from './services/event-listener.service';
import { LoadingService } from './services/loading.service';
import { PageTitleService } from './services/page-title.service';
import { PaymentProfileService } from './services/payment-profile.service';
import { PublicService } from './services/public.service';
import { TermsConditionsService } from './services/terms-conditions.service';
import { TokenService } from './services/token.service';

@NgModule({
    declarations: [
        AppComponent,
        LoadingComponent,
        PadDialogV2,
        PadDialog,
        TCTextCaComponent,
        TCTextUsComponent,
    ],
    imports: [
        GlobalModule,
        HttpClientModule,
        BrowserModule,
        AppRoutingModule,
        NoopAnimationsModule,
        MaterialModule,
        StylesBehaviorModule
    ],
    providers: [
        TokenService,
        PageTitleService,
        EventListenerService,
        LoadingService,
        TermsConditionsService,
        PublicService,
        AmountService,
        GlobalHelper,
        CommonModule,
        CurrencyPipe,
        DatePipe,
        ValidatorsHelper,
        PaymentProfileService,
        { provide: HTTP_INTERCEPTORS, useClass: PublicUrlsEnabledInterceptor, multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
