<svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="45" cy="44.7037" r="42.5" stroke="#D24141" stroke-width="4"/>
    <g clip-path="url(#clip0_925_7393)">
        <path d="M60.8333 31.1604L57.5433 27.8704L44.5 40.9138L31.4567 27.8704L28.1667 31.1604L41.21 44.2038L28.1667 57.2471L31.4567 60.5371L44.5 47.4938L57.5433 60.5371L60.8333 57.2471L47.79 44.2038L60.8333 31.1604Z"
              fill="#D24141"/>
    </g>
    <defs>
        <clipPath id="clip0_925_7393">
            <rect width="56" height="56" fill="white" transform="translate(16.5 16.2037)"/>
        </clipPath>
    </defs>
</svg>
