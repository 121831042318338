import {Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {PublicService} from './public.service';
import {TransactionMethod} from "../models/TransactionMethod";
import {CustomerCustomConfiguration} from "../models/Customer";

@Injectable()
export class PaymentProfileService {

    private subscriptionArray: Subscription[] = [];

    constructor(
        private _route: ActivatedRoute,
        private _publicService: PublicService,
        ) { }

    ngOnDestroy(): void {
        this.subscriptionArray.forEach(item => {
            item.unsubscribe();
        });
    }

    isSdkRedirect(): boolean {
        return this._route.snapshot.queryParamMap.get('sdkredirect') === 'true';
    }

    redirectIfPaymentProfileSuccessIsAllowed(): void {
        if (this.isSdkRedirect()) {
            return;
        }

        let subscribe = this._publicService.getCustomerCustomConfiguration().subscribe(config => {
            if (config && config.PaymentProfileConnectSuccessRedirectUrl) {
                window.location.href = config.PaymentProfileConnectSuccessRedirectUrl;
            }
        });

        this.subscriptionArray.push(subscribe);
    }

    getCtaText(defaultEnglish: string, defaultFrench: string, rails: TransactionMethod, currentLanguage: string){
        let customerConfig = this._publicService.customerCustomConfiguration.getValue();

        if (currentLanguage == "fr" && this._publicService.customerCustomConfiguration.getValue()) {
            return this.getText(false, rails, customerConfig);
        }

        if (currentLanguage == "fr") {
            return defaultFrench;
        }

        if (this._publicService.customerCustomConfiguration.getValue()) {
            let text = this.getText(true, rails, customerConfig);
            if(text){
                return text;
            }
        }

        return defaultEnglish;
    }

    getLoadingText(isAddUser:boolean, currentLanguage: string) {
        let customerConfig = this._publicService.customerCustomConfiguration.getValue();

        if (!isAddUser) {
            return "INVOICE.TEXT.PROCESSINGPAYMENT"
        }

        let text = "CONNECT.CREATINGUSERPAYMENTPROFILE"

        if(customerConfig){
            if(customerConfig?.CtaTextLoadingMessageEnglish){
                text = customerConfig.CtaTextLoadingMessageEnglish;
            }

            if(currentLanguage == "fr" && customerConfig?.CtaTextLoadingMessageFrench){
                text = customerConfig.CtaTextLoadingMessageFrench;
            }
        }

        return text;
    }

    getSuccessText(currentLanguage: string) {
        let customerConfig = this._publicService.customerCustomConfiguration.getValue();

        let text = "CONNECT.USERADDED"

        if(customerConfig){
            if(customerConfig?.CtaTextSuccessMessageEnglish){
                text = customerConfig.CtaTextSuccessMessageEnglish;
            }

            if(currentLanguage == "fr" && customerConfig?.CtaTextSuccessMessageFrench){
                text = customerConfig.CtaTextSuccessMessageFrench;
            }
        }

        return text;
    }

    getText(isEnglish: boolean, rails: TransactionMethod, customerConfig: CustomerCustomConfiguration){
        if(isEnglish){
            switch (rails) {
                case TransactionMethod.CreditCard:
                    return customerConfig?.CtaTextCreditCardEnglish;
                case TransactionMethod.Eft:
                    return customerConfig?.CtaTextEftEnglish;
                case TransactionMethod?.Interac:
                    return customerConfig?.CtaTextInteracEnglish;
                case TransactionMethod.VisaDirect:
                    return customerConfig?.CtaTextVisaDirectEnglish;
                case TransactionMethod.Ach:
                        return customerConfig?.CtaTextAchEnglish;
            }
        }

        switch (rails) {
            case TransactionMethod.CreditCard:
                return customerConfig?.CtaTextCreditCardFrench;
            case TransactionMethod.Eft:
                return customerConfig?.CtaTextEftFrench;
            case TransactionMethod?.Interac:
                return customerConfig?.CtaTextInteracFrench;
            case TransactionMethod.VisaDirect:
                return customerConfig?.CtaTextVisaDirectFrench;

        }
    }
}
