import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-loader-inner-components',
    templateUrl: './loader-inner-components.component.html',
    styleUrls: ['./loader-inner-components.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LoaderInnerComponentsComponent implements OnInit {
    @Input() isLoading: boolean;
    @Input() showDefaultMessage: boolean = true;

    constructor() {
    }

    ngOnInit(): void {
    }
}
