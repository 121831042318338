<div class="w-full" id="padDialogModal">
  <div class="text-sm flex flex-col w-full list overflow-y-auto overflow-x-hidden">
    <div class="space-y-3">
      <b class="uppercase">{{ 'CONNECT.TC.INSTANTDEPOSIT' | translate }}</b>
      <p class="capitalize">{{ 'CONNECT.TC.LASTUPDATED' | translate }}</p>

      <p>{{ 'CONNECT.TC.INTRODUCTION' | translate: { customerName: customerName } }}</p>

      <p class="font-bold">{{ 'CONNECT.TC.GENERAL.TITLE' | translate }}</p>
      <p>{{ 'CONNECT.TC.GENERAL.FIRST' | translate}}</p>

      <p class="font-bold">{{ 'CONNECT.TC.USINGTHESERVICE.TITLE' | translate }}</p>
      <p>{{ 'CONNECT.TC.USINGTHESERVICE.FIRST' | translate: { customerName: customerName } }}</p>
      <p>{{ 'CONNECT.TC.USINGTHESERVICE.SECOND' | translate }}</p>
      <p>{{ 'CONNECT.TC.USINGTHESERVICE.THIRD' | translate }}</p>
      <p>{{ 'CONNECT.TC.USINGTHESERVICE.FOURTH' | translate }}</p>
      <p>{{ 'CONNECT.TC.USINGTHESERVICE.FIFTH' | translate }}</p>
      <p>{{ 'CONNECT.TC.USINGTHESERVICE.SIXTH' | translate }}</p>

      <p class="font-bold">{{ 'CONNECT.TC.DATAPRIVACY.TITLE' | translate }}</p>
      <p> 
        {{ 'CONNECT.TC.DATAPRIVACY.FIRST' | translate }}
        <a rel="noopener noreferrer" href="{{ 'CONNECT.TC.DATAPRIVACY.LINKSECOND' | translate }}" target="_blank">{{ 'CONNECT.TC.DATAPRIVACY.LINKSECOND' | translate }}</a> 
        {{ 'CONNECT.TC.DATAPRIVACY.THIRD' | translate: { customerName: customerName } }}
        {{ 'CONNECT.TC.DATAPRIVACY.FIFTH' | translate: { customerName: customerName } }}
      </p>
      <p>{{ 'CONNECT.TC.DATAPRIVACY.SIXTH' | translate: { customerName: customerName } }}</p>

      <p class="font-bold">{{ 'CONNECT.TC.GRANTOFPOWEROFATTORNEY.TITLE' | translate }}</p>
      <p>{{ 'CONNECT.TC.GRANTOFPOWEROFATTORNEY.FIRST' | translate }}</p>

      <p class="font-bold">{{ 'CONNECT.TC.INTERACANDSERVICE.TITLE' | translate }}</p>
      <p>{{ 'CONNECT.TC.INTERACANDSERVICE.FIRST' | translate: { customerName: customerName } }}</p>
      
      <p class="font-bold">{{ 'CONNECT.TC.ZUMRAILSROLE.TITLE' | translate }}</p>
      <p>{{ 'CONNECT.TC.ZUMRAILSROLE.FIRST' | translate: { customerName: customerName } }}</p>
      
      <p class="font-bold">{{ 'CONNECT.TC.PROPRIETARYRIGHTS.TITLE' | translate }}</p>
      <p>{{ 'CONNECT.TC.PROPRIETARYRIGHTS.FIRST' | translate: { customerName: customerName } }}</p>

      <p class="font-bold">{{ 'CONNECT.TC.REPRESENTATIONSANDWARRANTIES.TITLE' | translate }}</p>
      <p class="font-bold">{{ 'CONNECT.TC.REPRESENTATIONSANDWARRANTIES.FIRST' | translate }}</p>
      <p>{{ 'CONNECT.TC.REPRESENTATIONSANDWARRANTIES.SECOND' | translate }}</p>
      <p class="pl-4">{{ 'CONNECT.TC.REPRESENTATIONSANDWARRANTIES.THIRD' | translate }}</p>
      <p class="pl-4">{{ 'CONNECT.TC.REPRESENTATIONSANDWARRANTIES.FOURTH' | translate }}</p>
      <p class="pl-4">{{ 'CONNECT.TC.REPRESENTATIONSANDWARRANTIES.FIFTH' | translate }}</p>
      <p>{{ 'CONNECT.TC.REPRESENTATIONSANDWARRANTIES.SIXTH' | translate }}</p>
      <p>{{ 'CONNECT.TC.REPRESENTATIONSANDWARRANTIES.SEVENTH' | translate }}</p>
      <p class="pl-4">{{ 'CONNECT.TC.REPRESENTATIONSANDWARRANTIES.EIGHTH' | translate }}</p>
      <p class="pl-4">{{ 'CONNECT.TC.REPRESENTATIONSANDWARRANTIES.NINETH' | translate }}</p>
      <p class="pl-4">{{ 'CONNECT.TC.REPRESENTATIONSANDWARRANTIES.TENTH' | translate }}</p>
      <p class="pl-4">{{ 'CONNECT.TC.REPRESENTATIONSANDWARRANTIES.ELEVENTH' | translate }}</p>

      <p class="font-bold">{{ 'CONNECT.TC.DISCLAIMERS.TITLE' | translate }}</p>
      <p>{{ 'CONNECT.TC.DISCLAIMERS.FIRST' | translate }}</p>
      <p>{{ 'CONNECT.TC.DISCLAIMERS.SECOND' | translate: { customerName: customerName } }}</p>

      <p class="font-bold">{{ 'CONNECT.TC.LIMITATIONSOFLIABILITY.TITLE' | translate }}</p>
      <p>{{ 'CONNECT.TC.LIMITATIONSOFLIABILITY.FIRST' | translate }}</p>

      <p class="font-bold">{{ 'CONNECT.TC.GOVERNINGLAWANDDISPUTES.TITLE' | translate }}</p>
      <p>{{ 'CONNECT.TC.GOVERNINGLAWANDDISPUTES.FIRST' | translate }}</p>

      <p class="font-bold">{{ 'CONNECT.TC.MODIFICATIONOFTERMSOFSERVICENOTICES.TITLE' | translate }}</p>
      <p>{{ 'CONNECT.TC.MODIFICATIONOFTERMSOFSERVICENOTICES.FIRST' | translate }}</p>

      <p class="font-bold">{{ 'CONNECT.TC.ASSIGNMENT.TITLE' | translate }}</p>
      <p>{{ 'CONNECT.TC.ASSIGNMENT.FIRST' | translate }}</p>

      <p class="font-bold">{{ 'CONNECT.TC.TERMINATIONSUSPENSIONORREVOCATIONOFACCESS.TITLE' | translate }}</p>
      <p>{{ 'CONNECT.TC.TERMINATIONSUSPENSIONORREVOCATIONOFACCESS.FIRST' | translate: { customerName: customerName } }}</p>

      <p class="font-bold">{{ 'CONNECT.TC.SURVIVAL.TITLE' | translate }}</p>
      <p>{{ 'CONNECT.TC.SURVIVAL.FIRST' | translate }}</p>

      <p class="font-bold">{{ 'CONNECT.TC.MISCELLANEOUS.TITLE' | translate }}</p>
      <p>{{ 'CONNECT.TC.MISCELLANEOUS.FIRST' | translate: { customerName: customerName } }}</p>
    </div>
    <div class="w-full mt-4 mb-2 bottom-0 flex content-center text-right justify-between relative"> 
      <button
        class="hover:bg-gray-100 w-full button border rounded shadow button-cancel focus:outline-non"
        (click)="onActionClick(false)"> <span class="center-text-flex">{{'LABEL.NOTHANKS' | translate }}</span>
      </button> 
      <button class="button button-company-color button-text-color w-full h-auto ml-1 focus:outline-none"
        (click)="onActionClick(true)"> <span class="center-text-flex">{{'LABEL.YESIAGRRE' | translate }}</span>
      </button> 
    </div>
  </div>
</div>