import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class TermsConditionsService {
    private displayTermsAggregationSubject: BehaviorSubject<boolean>;
    public displayTermsAggregation: Observable<boolean>;

    constructor
    (
    )
    {
        this.displayTermsAggregationSubject = new BehaviorSubject<boolean>(false);
        this.displayTermsAggregation = this.displayTermsAggregationSubject.asObservable();
    }

    updateDisplayTermsAggregation(display: boolean) {
        this.displayTermsAggregationSubject.next(display);
    }
}