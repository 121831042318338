import {Pipe, PipeTransform} from "@angular/core";
import {AbstractControl, Form} from "@angular/forms";

@Pipe({
    name: 'zumFieldGetClassPipe'
})

export class ZumFieldGetClassPipe implements PipeTransform {
    transform(obj: AbstractControl, submitted: boolean): string {

        if (obj.invalid && (obj.touched || submitted))
            return "mb-0"

        if(obj.valid)
            return "mb-4"

        return "mb-4"
    }
}