import { Injectable } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { Subscription } from "rxjs";
import { TransactionMethod } from "../models/TransactionMethod";
import { OptionalQueryParams } from "../pages/connect/types";
import { CountryType } from "../models/CountryType";
import { buildToOptionalQueryParams, normalizeParamsToLowerCaseProperties } from "../helpers/OptionalQueryParamsHelper";

type TransactionMethodsCA = TransactionMethod.CreditCard | TransactionMethod.Eft | TransactionMethod.Interac | TransactionMethod.VisaDirect;
type OptionalQueryParamsByPaymentTypeConfigCA = Record<TransactionMethodsCA , Partial<OptionalQueryParams>>;

type TransactionMethodsUS = TransactionMethod.Ach | TransactionMethod.CreditCardUs;
type OptionalQueryParamsByPaymentTypeConfigUS = Record<TransactionMethodsUS , Partial<OptionalQueryParams>>;

export type ConnectingFlow = 'AddingPaymentProfile' | 'InvoiceOrSubscription';

const DEFAULT_RESTRICTIVE_PARAMS = new OptionalQueryParams({hideCreditCardAddress: true,   hideShippingAddress: true, displayTermsAndCondition: true, displayTermsAndConditions: true, removeLogo: false, reduceSpacing: false, smallButtons: false});

const DEFAULT_INVOICE_PARAMS_CA:OptionalQueryParamsByPaymentTypeConfigCA = {
    Eft:            {hideCreditCardAddress: true,   hideShippingAddress: true,  disablePreFilledFields: false, displayTermsAndCondition: true, displayTermsAndConditions: false, removeLogo: false, reduceSpacing: false, smallButtons: false},
    Interac:        {hideCreditCardAddress: true,   hideShippingAddress: true,  disablePreFilledFields: false, displayTermsAndCondition: true, displayTermsAndConditions: false, removeLogo: false, reduceSpacing: false, smallButtons: false},
    CreditCard:     {hideCreditCardAddress: true,   hideShippingAddress: true,  disablePreFilledFields: false, displayTermsAndCondition: true, displayTermsAndConditions: false, removeLogo: false, reduceSpacing: false, smallButtons: false},
    VisaDirect:     {hideCreditCardAddress: false,  hideShippingAddress: true,  disablePreFilledFields: false, displayTermsAndCondition: true, displayTermsAndConditions: false, removeLogo: false, reduceSpacing: false, smallButtons: false},
}

const DEFAULT_INVOICE_PARAMS_US:OptionalQueryParamsByPaymentTypeConfigUS = {
    CreditCardUs:   {hideCreditCardAddress: false, hideShippingAddress: true, disablePreFilledFields: false, displayTermsAndCondition: false, displayTermsAndConditions: true, removeLogo: false, reduceSpacing: false, smallButtons: false},
    Ach:            {hideCreditCardAddress: false, hideShippingAddress: true, disablePreFilledFields: false, displayTermsAndCondition: false, displayTermsAndConditions: true, removeLogo: false, reduceSpacing: false, smallButtons: false},
}
const DEFAULT_ADD_PAYMENT_PROFILE_PARAMS_CA:OptionalQueryParamsByPaymentTypeConfigCA = {
    Eft:            {hideCreditCardAddress: true,   hideShippingAddress: true,  disablePreFilledFields: false, displayTermsAndCondition: true, displayTermsAndConditions: false, removeLogo: false, reduceSpacing: false, smallButtons: false},
    Interac:        {hideCreditCardAddress: true,   hideShippingAddress: true,  disablePreFilledFields: false, displayTermsAndCondition: true, displayTermsAndConditions: false, removeLogo: false, reduceSpacing: false, smallButtons: false},
    CreditCard:     {hideCreditCardAddress: true,   hideShippingAddress: true,  disablePreFilledFields: false, displayTermsAndCondition: true, displayTermsAndConditions: false, removeLogo: false, reduceSpacing: false, smallButtons: false},
    VisaDirect:     {hideCreditCardAddress: false,  hideShippingAddress: false, disablePreFilledFields: false, displayTermsAndCondition: true, displayTermsAndConditions: false, removeLogo: false, reduceSpacing: false, smallButtons: false},
}

const DEFAULT_ADD_PAYMENT_PROFILE_PARAMS_US:OptionalQueryParamsByPaymentTypeConfigUS = {
    CreditCardUs:   {hideCreditCardAddress: false, hideShippingAddress: true, disablePreFilledFields: false, displayTermsAndCondition: false, displayTermsAndConditions: true, removeLogo: false, reduceSpacing: false, smallButtons: false},
    Ach:            {hideCreditCardAddress: false, hideShippingAddress: true, disablePreFilledFields: false, displayTermsAndCondition: false, displayTermsAndConditions: true, removeLogo: false, reduceSpacing: false, smallButtons: false},
}

@Injectable({
    providedIn: 'root'
})
export class OptionalQueryParamsService{
    public optionalParams: OptionalQueryParams = DEFAULT_RESTRICTIVE_PARAMS;
    private subscriptionArray: Subscription[] = [];


    constructor(private _route: ActivatedRoute) {}


    private getDefaultParamsValueCA = (flow: ConnectingFlow, transactionMethod: TransactionMethodsCA) => {
        const allTransactionMethodConfigs =  flow === 'AddingPaymentProfile' ? DEFAULT_ADD_PAYMENT_PROFILE_PARAMS_CA : DEFAULT_INVOICE_PARAMS_CA;
        return allTransactionMethodConfigs[transactionMethod];
    }

    private getDefaultParamsValueUS = (flow: ConnectingFlow, transactionMethod: TransactionMethodsUS) => {
        const allTransactionMethodConfigs =  flow === 'AddingPaymentProfile' ? DEFAULT_ADD_PAYMENT_PROFILE_PARAMS_US : DEFAULT_INVOICE_PARAMS_US;
        return allTransactionMethodConfigs[transactionMethod];
    }

    private getDefaultParamsValue = (countryType: CountryType, flow: ConnectingFlow, transactionMethod: TransactionMethod) => {
        if(countryType === CountryType.Canada){
            return this.getDefaultParamsValueCA(flow, transactionMethod as TransactionMethodsCA);
        }
        return this.getDefaultParamsValueUS(flow, transactionMethod as TransactionMethodsUS);
    }

    private digestRawParamsSuccess =  (params: Params) => {
        const rawParams = params || {};
        const normalizedRawParams = normalizeParamsToLowerCaseProperties(rawParams);
        this.optionalParams = buildToOptionalQueryParams(normalizedRawParams, DEFAULT_RESTRICTIVE_PARAMS);
    };

    public digestRawParams = () => {
        let digestRawParamsSubscription = this._route.queryParams.subscribe(
            this.digestRawParamsSuccess.bind(this)
        );
        this.subscriptionArray.push(digestRawParamsSubscription);
    }

    private digestConditionalParamsSuccess = (defaultValues: Partial<OptionalQueryParams>) => (params: Params) => {
        const rawParams = params || {};
        const normalizedRawParams = normalizeParamsToLowerCaseProperties(rawParams);

        this.optionalParams = buildToOptionalQueryParams(normalizedRawParams, defaultValues);
    };

    public digestConditionalParams = (countryType: CountryType, flow: ConnectingFlow, transactionMethod: TransactionMethod) => {
        const defaultValues = this.getDefaultParamsValue(countryType, flow, transactionMethod);
        let digestParamsByCountryAndFlowSubscription = this._route.queryParams.subscribe(
            this.digestConditionalParamsSuccess(defaultValues).bind(this)
        );
        this.subscriptionArray.push(digestParamsByCountryAndFlowSubscription);
    }


    isSdkRedirect(): boolean {
        return this._route.snapshot.queryParamMap.get("sdkredirect") === "true";
    }

    ngOnDestroy(): void {
        this.subscriptionArray.forEach((item) => {
            item.unsubscribe();
        });
    }
}
