import { Params } from "@angular/router";
import { OptionalQueryParamNames, OptionalQueryParams } from "../pages/connect/types";
import { digestValue} from "./ParamsHelper";

const OptionalQueryParamsFieldTypeMapper:Partial<OptionalQueryParams> = {
    displayTermsAndCondition: true,
    displayZumRailsLogo: true,
    displayDetailsColumn: true,
    displayPhoneNumber: true,
    displayFullName: true,
    displayAccountType: true,
    hideRailsName: true,
    disablePreFilledFields: true,
    hideFirstName: true,
    hideLastName: true,
    hideFullName: true,
    hideEmail: true,
    hideCreditCardAddress: true,
    hideShippingAddress: true,
    amount: 1,
    showMethodSelector: true,
    hideBackButton: true,
    triggerAggregation: true,
    displayTermsAndConditions:true,
    sdkredirect:true,
    removeLogo:false,
    reduceSpacing:false,
    addCancelButton:false,
    smallButtons: false,
    displayPostalCodeOnly: false,
    tecUrl: false
}

export const normalizeParamsToLowerCaseProperties = (params: Params) => {
    var obj = new Object();

    for (let prop in params) {
        obj[prop.toLowerCase()] = params[prop];
    }

    params = obj;
    return params;
}

const validateAccountType = (value?:string) => {
    const regex = /^(business|personal)$/i;
    return regex.test(value);
}

const normalizeAccountType = (value?: string) => {
    if(!value || !validateAccountType(value)){
        return undefined;
    }

    if(value.toLocaleLowerCase() === 'personal'){
        return 'Personal'
    }

    return 'Business';
}

const normalizeOptionalParam = (value: unknown, paramName:string) => {
    switch (paramName) {
        case 'accounttype':
            return normalizeAccountType(value as string);
        case 'displaybankconnection':
            return normalizeBankConnection(value as string);
        default:
            return value;
    }
}

const validateBankConnection = (lowercaseValue?:string) => {    
    const regex = /^(none|all|hideonconsentpage)$/i;
    return regex.test(lowercaseValue);
}

const normalizeBankConnection = (value: string) => {
    if(!value){
        return undefined;
    }

    const lowercaseValue = value.toLocaleLowerCase();

    if(!validateBankConnection(lowercaseValue)){
        return undefined;
    }

    return lowercaseValue;
}

export const buildToOptionalQueryParams = (params: Params, defaultValues?:Partial<OptionalQueryParams>): OptionalQueryParams => {
    const instance = new OptionalQueryParams({...defaultValues});
    const keys = Object.keys(OptionalQueryParamNames);

    keys.forEach(propertyName => {
        const key = OptionalQueryParamNames[propertyName];
        if (params.hasOwnProperty(key)) {
            const value = params[key];
            const type = typeof OptionalQueryParamsFieldTypeMapper[propertyName];
            const digestedValue = digestValue(value, type);
            const normalizedValue = normalizeOptionalParam(digestedValue, key);

            if( normalizedValue !== undefined && normalizedValue !== null){
                (instance as any)[propertyName] = normalizedValue;
            }
        }
    });

    return instance;
}