import { Component, Input } from '@angular/core';

@Component({
    selector: 'payment-loader',
    templateUrl: './payment-loader.component.html',
    styleUrls: ['./payment-loader.component.scss']
  })
export class PaymentLoader{
  @Input() label = "";

  public loading = true;
  public showDefaultText = false;
}
