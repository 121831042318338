import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
//It is ideal that the changes made are also reflected on the zumCurrency pipe on the Portal.UI project

@Pipe({
    name: 'zumCurrency',
  })
  export class ZumCurrencyPipe implements PipeTransform {
    transform(
        value: any = 0,
        currencyCode: string = 'CAD'
    ): string | null {
        value = value || 0;
        
        const currencyFractionDigits = new Intl.NumberFormat('en-CA', {
            style: 'currency',
            currency: currencyCode,
        }).resolvedOptions().maximumFractionDigits;
        
        const currrencyValue = parseFloat(parseFloat(value).toFixed(2)).toLocaleString('en-CA', {
            minimumFractionDigits: 2,
            maximumFractionDigits: currencyFractionDigits
        });

        return getCurrencySymbol(currencyCode, "narrow", 'en-CA') + currrencyValue + " " + currencyCode;
    }
}