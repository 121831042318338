import {Component, Input} from "@angular/core";

@Component({
    selector: "connect-info-header-v2",
    templateUrl: "./connect-info-header.component-v2.html",
    styleUrls: ["./connect-info-header.component-v2.scss"],
})

export class ConnectInfoHeaderComponentV2 {
    @Input() image: string = null;

}
