export const environment = {
    production: true,
    apiUrl: "https://api-sandbox.zumrails.com/api",
    aggregationUrl: "https://sandbox-cdn.aggregation.zumrails.com/sandbox/connector.js",
    testInstitution: true,
    customerAggregation: false,
    aggregationTestCustomerId: '94505e9d-342f-4a57-9365-778dbdd7be34',
    threeDS: "https://api-sandbox.3dsintegrator.com/v2.2",
    validateVersion: true,
    trackKey: "uEouS8tMDgXgdukpTtG5ir8Ux0Jc4RwH",
    enableTrack: true,
    customersWithTecUrlEnabled: [
        "81ee2bcc-1f71-43cc-b520-69577df3b866",
        "1aaa6041-1a24-49fb-9df0-17b3906cdd66",
        "46c29c7c-c9a1-4599-9a8f-3ac00788496f",
        "5b042bd9-9b95-447f-84f1-1b946f40fa61",
        "1fed94e7-b8da-4046-9450-7d661c844d3b"
    ]
};
