import { HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { ConnectErrorCode } from "../models/ConnectErrorCode";
import { ConnectErrorMessage } from "../models/ConnectErrorMessage";

export const isPublicUrlsException = (response: HttpErrorResponse, router: Router) => {
    if (response.error.responseException.exceptionMessage == ConnectErrorMessage.TokenExpired)
    {
        router.navigate(['/error'], { queryParams: { type: ConnectErrorCode.TokenExpired } });
    }
}

export const setupTokenForManageUserPortal = (token?:string) => {

    if(!token){
      return token;
    }

    let tokenArray = token.split('|');
      if(tokenArray){
        let emailIndex = tokenArray.findIndex(x => x.includes('@'))
        tokenArray[emailIndex] = encodeURIComponent(tokenArray[emailIndex]);
        tokenArray[emailIndex] = tokenArray[emailIndex].replace(/%\w\w/g, match => match.toLowerCase());
        token = tokenArray.join('|');
        return token;
      }
}
    

