import { CommonModule } from "@angular/common";
import { ForceVersionTwoStylesComponent } from "./force-version-two-styles.component";
import { NgModule } from "@angular/core";


@NgModule({
    declarations: [ForceVersionTwoStylesComponent],
    imports: [CommonModule],
    exports: [ForceVersionTwoStylesComponent],
  })
  export class StylesBehaviorModule {}
