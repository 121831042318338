import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { GetTokenInformationOutput } from '../models/GetTokenInformationOutput';
import { ApiResponse } from '../models/ApiResponse';
import { environment } from 'src/environments/environment';
import { ConnectGetTokenInformationFor } from '../models/ConnectGetTokenInformationFor';
import { ConnectErrorCode } from '../models/ConnectErrorCode';
import { Router } from '@angular/router';

@Injectable()
export class TokenService {
    root = "";

    private currentTokenInformationOutputSubject: BehaviorSubject<GetTokenInformationOutput>;
    public currentTokenInformationOutput: Observable<GetTokenInformationOutput>;

    constructor
    (
        private http: HttpClient,
        private router: Router,
    )
    {
        this.root = environment.apiUrl;
        
        this.currentTokenInformationOutputSubject = new BehaviorSubject<any>(null);
        this.currentTokenInformationOutput = this.currentTokenInformationOutputSubject.asObservable();
    }

    loadTokenInformationIntoService(token: string) {
        let input = {
            token: token,
            ConnectGetTokenInformationFor: ConnectGetTokenInformationFor.Basic
        };
        
        this.http.post<ApiResponse<GetTokenInformationOutput>>(this.root + `/connect/GetTokenInformation`, input)
            .subscribe((response: ApiResponse<GetTokenInformationOutput>) => {
                if (!response.isError) {
                    
                    this.currentTokenInformationOutputSubject.next(response.result);
                    return;
                }

                this.goToError(ConnectErrorCode.TokenExpired);
                return;

            }, (error) => {
                
                this.goToError(ConnectErrorCode.TokenExpired);
            });
    }

    goToError(erroCode: ConnectErrorCode): void {
        this.router.navigate(['/error'], { queryParams: { type: erroCode } });
    }

    updateTokenInformation(getTokenInformationOutput: GetTokenInformationOutput) {
        
        this.currentTokenInformationOutputSubject.next(getTokenInformationOutput);
    }

    getTokenInformationFromService(): GetTokenInformationOutput {
        return this.currentTokenInformationOutputSubject.value;
    }

    getTokenInformationFromApi(token: string, connectGetTokenInformationFor: ConnectGetTokenInformationFor): Observable<ApiResponse<GetTokenInformationOutput>> {
        let input = {
            token: token,
            ConnectGetTokenInformationFor: connectGetTokenInformationFor
        };
        
        return this.http.post<ApiResponse<GetTokenInformationOutput>>(this.root + `/connect/GetTokenInformation`, input);
    }

    removeTokenInRedis(token: string): Observable<ApiResponse<any>> {
        let input = {
            token: token
        };
        
        return this.http.post<ApiResponse<any>>(this.root + `/connect/RemoveToken`, input);
    }
}