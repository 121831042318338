import {environment} from "../../environments/environment";
import { EventListenerStep } from "../models/EventListenerSteps";

const warnMessage = "It was not possible to interact with the tracking library";

export function trackActivity(event: EventListenerStep, pageData: any) {
    if (!environment.enableTrack) return;

    try {
        getInstance().track(event, {...pageData, step: event});
    } catch {
        console.warn(warnMessage);
    }
}

function getInstance() {
    if (!(window as any).analytics) {
        insertLibrary();
        (window as any).analytics.load(environment.trackKey);
    }

    return (window as any).analytics;
}

function insertLibrary() {
    if (!environment.trackKey) {
        console.warn("Could not initialize the tracking library due to configuration issues.");
        return;
    }

    let libraryInclude = document.createElement("script");
    libraryInclude.type = `text/javascript`;
    libraryInclude.id = "track-library";
    libraryInclude.text = `!function(){var analytics=window.analytics=window.analytics
        ||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error
        &&console.error("Segment snippet included twice.");else{analytics.invoked=!0;
        analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview",
        "identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware",
        "addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];
        analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);
        t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++)
        {var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function
        (key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;
        t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName
        ("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey=
        "${environment.trackKey}";;analytics.SNIPPET_VERSION="4.15.3";}}();`;
    document.head.appendChild(libraryInclude);
}
