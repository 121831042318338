import { Injectable } from "@angular/core";
import { ConnectErrorCode } from "../models/ConnectErrorCode";
import { GetTokenInformationOutput } from "../models/GetTokenInformationOutput";
import { TokenService } from "./token.service";
import { EventListenerSteps } from "../models/EventListenerSteps";

@Injectable()
export class EventListenerService {
    getTokenInformationOutput: GetTokenInformationOutput | undefined;

    constructor(private tokenService: TokenService) {}

    resize(height: string) {
        var data = {
            step: "RESIZE",
            height: height,
        };

        window.top.postMessage(data, "*");
    }

    closePopup() {
        this.getTokenInformationOutput =
            this.tokenService.getTokenInformationFromService();
        this.tokenService
            .removeTokenInRedis(this.getTokenInformationOutput?.Token)
            .subscribe(
                (data) => {
                    var windowData = {
                        step: "CLOSE",
                    };

                    window.top.postMessage(windowData, "*");
                },
                (error) => {
                    if (error.status == 400) {
                        var data = {
                            step: "CLOSE",
                        };

                        window.top.postMessage(data, "*");
                    }
                }
            );
    }

    stepChange(step: EventListenerSteps) {
        this.getTokenInformationOutput =
            this.tokenService.getTokenInformationFromService();
        this.tokenService
            .removeTokenInRedis(this.getTokenInformationOutput?.Token)
            .subscribe(
                (data) => {
                    var windowData = {
                        step: step.toString().toUpperCase(),
                    };

                    window.top.postMessage(windowData, "*");
                },
                (error) => {
                    if (error.status == 400) {
                        var data = {
                            step: step.toString().toUpperCase(),
                        };

                        window.top.postMessage(data, "*");
                    }
                }
            );
    }

    success(
        invoiceId?: any,
        userId?: any,
        transactionId?: any,
        subscriptionId?: any,
        origin?: any,
        clientUserId?: any,
        extraField1?: any,
        extraField2?: any,
        paymentInstrumentId?: any,
        requestId?: any
    ) {
        var data = {
            step: "SUCCESS",
            invoiceId: invoiceId,
            userId: userId,
            clientUserId: clientUserId,
            extraField1: extraField1,
            extraField2: extraField2,
            transactionId: transactionId,
            subscriptionId: subscriptionId,
            origin: origin,
            paymentInstrumentId: paymentInstrumentId,
            requestId: requestId,
        };

        window.top.postMessage(data, "*");
    }

    error(error: ConnectErrorCode, errorMessage: string = undefined) {
        var data = {
            step: "ERROR",
            error,
            errorMessage,
        };

        window.top.postMessage(data, "*");
    }

    pageLoaded() {
        var data = {
            step: "PAGE-LOAD",
        };

        window.top.postMessage(data, "*");
    }
}
