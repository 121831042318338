import { Subject } from "rxjs";
import { filter } from "rxjs/operators";
import { AmountChangedEvent } from "./AmountChangedEvent";

export class AmountService {

    private amountChangeSource = new Subject<any>();

    constructor() { }

    next(sender: string, amount: number) {
        this.amountChangeSource.next(new AmountChangedEvent(sender, amount));
    }

    amount(listener: string) {
       return this.amountChangeSource;
    }
}