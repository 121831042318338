import { Currency } from "./Currency";
import { ProductOutPutDTO } from "./Product";

export class ProductPriceOutputDTO {
  Id?: string;
  ProductId?: string;
  ProductPriceType?: ProductPriceType;
  Price?: number;
  PerUnit?: number;
  Frequency?: ProductPriceFrequency;
  PlanName?: string;
  PlanDescription?: string;
  BillingPeriod?: ProductBillingPeriod;
  CustomBillingPeriod?: number;
  CustomFrequencyBillingPeriod?: ProductBillingPeriodCustom;
  EnableSubscriptionEndDate?: boolean;
  SubscriptionEndDate?: string;
  EnableFreeTrial?: boolean;
  FreeTrialInDays?: number;
  EnableSetupFee?: boolean;
  SetupFee?: number;
  Currency?: Currency;
  ProductPriceStatus: ProductStatus;
  Product?: ProductOutPutDTO;
}

export enum ProductPriceFrequency {
  OneTime = 'OneTime',
  Recurring = 'Recurring'
}

export enum ProductBillingPeriod {
  Monthly = 'Monthly',
  Weekly = 'Weekly',
  Biweekly = 'Biweekly',
  Annually = 'Annually',
  Custom = 'Custom',
}

export enum ProductBillingPeriodCustom {
  Months = 'Months',
  Weeks = 'Weeks',
  Days = 'Days',
}

export enum ProductStatus {
  Active = 'Active',
  Archived = 'Archived',
}

export enum ProductPriceType {
  Standard = 'Standard',
  Package = 'Package'
}
