import {CommonModule} from '@angular/common';
import {HttpClient} from "@angular/common/http";
import {NgModule} from "@angular/core";
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {InvalidControlScrollDirective} from './directives/invalid-control-scroll.directive';
import {IconsModule} from './modules/icons.module';
import {InvoiceErrorComponent} from './pages/iframe/error/error.component';
import {LoaderInnerComponentsComponent} from "./pages/iframe/loader-inner-components/loader-inner-components.component";
import {PaymentLoader} from './pages/iframe/payment-loader/payment-loader.component';
import {ZumCurrencyPipe} from './pipes/zumCurrency.pipe';
import {ZumDatePipe} from './pipes/zumDate.pipe';
import {ZumDateWithoutTZPipe} from './pipes/zumDateWithoutTZ.pipe';
import {InvoiceErrorComponentV2} from './pages/iframe/error-v2/error.component-v2';
import {
    ConnectInfoHeaderComponentV2
} from "./pages/connect/connect-wrapper/connect-wrapper-info/connect-info-header/connect-info-header.component-v2";
import {ErrorSvgV2Component} from "./pages/connect/svgs/error-svg-v2/error-svg-v2.component";
import {PaymentLoaderV2} from "./pages/iframe/payment-loader/payment-loader-v2.component";

@NgModule({
    declarations: [
        LoaderInnerComponentsComponent,
        InvoiceErrorComponent,
        ZumCurrencyPipe,
        ZumDatePipe,
        ZumDateWithoutTZPipe,
        PaymentLoader,
        PaymentLoaderV2,
        InvalidControlScrollDirective,
        InvoiceErrorComponentV2,
        ConnectInfoHeaderComponentV2,
        ErrorSvgV2Component
    ],
    imports: [
        CommonModule,
        IconsModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    exports: [
        LoaderInnerComponentsComponent,
        InvoiceErrorComponent,
        IconsModule,
        ZumCurrencyPipe,
        ZumDatePipe,
        ZumDateWithoutTZPipe,
        TranslateModule,
        PaymentLoader,
        PaymentLoaderV2,
        InvalidControlScrollDirective,
        InvoiceErrorComponentV2,
        ConnectInfoHeaderComponentV2,
        ErrorSvgV2Component
    ]
})
export class GlobalModule {
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}