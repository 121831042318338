import { Pipe, PipeTransform } from "@angular/core";
import { ZumDatePipe } from "./zumDate.pipe";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
    name: "zumDateWithoutTZ",
})
export class ZumDateWithoutTZPipe implements PipeTransform {
    datePipe: ZumDatePipe;

    constructor(translate: TranslateService) {
        this.datePipe = new ZumDatePipe(translate);
    }
    transform(value: string | Date, format: string = "yyyy-MM-dd", locale: string = "en"): string {
        return this.datePipe.transform(value, format, null, locale);
    }
}