import { Currency } from "src/app/models/Currency";
import { RecurrenceType } from "src/app/models/RecurrenceType";
import { RequestSendFundsType } from "src/app/models/RequestFunds";
import { AddressInputDTO } from "src/app/models/User";

export enum PaymentProfileType {
    BankAccount,
    VisaDirect,
    Interac,
    CreditCard
}
interface PaymentSuccess {
    tag: 'PaymentSuccess';
    userId?: string;
    transactionId?: string;
    recurrentTransactionId?: string;
    amount: number;
    redirectURL?: string;
    step: 'SUCCESS';
}

interface RecurrentSuccess {
    tag: 'RecurrentSuccess';
    amount: number;
    userId?: string;
    transactionId?: string;
    recurrentTransactionId?: string;
    redirectURL?: string;
    step: 'SUCCESS';
}

interface UserSuccess {
    tag: 'UserSuccess';
    redirectURL?: string;
    userId?: string;
    transactionId?: string;
    recurrentTransactionId?: string;
    paymentProfileType?:  PaymentProfileType
    step: 'SUCCESS';
}

interface FundingSourceSuccess {
    tag: 'FundingSourceSuccess';
    redirectURL?: string;
    userId?: string;
    transactionId?: string;
    recurrentTransactionId?: string;
    step: 'SUCCESS';
}

interface Error {
    tag: 'Error';
    explanation: string;
    redirectURL?: string;
    userId?: string;
    transactionId?: string;
    recurrentTransactionId?: string;
    errorResponse?: any;
    step: 'ERROR';
}

export type ConnectFinalResult = PaymentSuccess | UserSuccess | Error | FundingSourceSuccess | RecurrentSuccess;


export type RequestType = {
    tag: 'Amount',
    type: RequestSendFundsType,
    amount?: number,
    amountType: 'fixed' | 'open',
    recurrent?: RecurrentTransactionInformation
} | {tag: 'User'}
  | {tag: 'FundingSource'};

export interface RecurrentTransactionInformation {
    endDate?: Date;
    dayOfWeek?: string;
    dayOfMonth?: number;
    LastDayOfTheMonth?: Date;
    recurrenceType?: RecurrenceType;
    DisplayRecurrencyQuantityInConnect?: boolean
}

export interface UserConnectData {
    AccountType: string;
    CompanyName: string;
    FirstName: string;
    LastName: string;
    Email: string;
    PhoneNumber?: string;
    Amount?: number;
    Memo?: string;
    Comment?: string;
    ShippingAddress?: AddressInputDTO;
    ClientUserId?: string;
    ExtraField1?: string;
    ExtraField2?: string;
}

export class AccountType {
    static Personal = "Personal";
    static Business = "Business";
}

export class OptionalQueryParamNames{
    static firstName: string = "firstname";
    static lastName: string = "lastname";
    static companyName: string = "companyname";
    static accountType: string = "accounttype";
    static email: string = "email";
    static memo: string = "memo";
    static comment: string = "comment";
    static allowEft: string = "alloweft";
    static allowInterac: string = "allowinterac";
    static allowCreditCard: string = "allowcreditcard";
    static allowVisaDirect: string = "allowvisadirect";
    static allowAch: string = "allowach";
    static displayTermsAndCondition: string = "displaytermsandcondition";
    static displayZumRailsLogo: string = "displayzumrailslogo";
    static displayDetailsColumn: string = "displaydetailscolumn";
    static displayPhoneNumber: string = "displayphonenumber";
    static displayFullName: string = "displayfullname";
    static displayAccountType: string = "displayaccounttype";
    static hideRailsName: string = "hiderailsname";
    static backgroundColor: string = "backgroundcolor"
    static disablePreFilledFields: string = "disableprefilledfields";
    static language: string = "language";
    static hideFirstName: string = "hidefirstname";
    static hideLastName: string = "hidelastname";
    static hideFullName: string = "hidefullname";
    static hideEmail: string = "hideemail";
    static hideCreditCardAddress: string = "hidecreditcardaddress";
    static hideShippingAddress: string = "hideshippingaddress";
    static brandColor: string = "brandcolor";
    static accentColor: string = "accentcolor";
    static foregroundColor: string = "foregroundcolor";
    static customerName: string = "customername";
    static amount: string = "amount";
    static showMethodSelector: string = "showmethodselector"
    static creditCardAddresLine1: string = 'creditcardaddresline1';
    static creditCardAddresLine2: string = 'creditcardaddresline2';
    static creditCardCity: string = 'creditcardcity';
    static creditCardProvince: string = 'creditcardprovince';
    static creditCardPostalCode: string = 'creditcardpostalcode';
    static creditCardCountry: string = 'creditcardcountry';
    static hideBackButton: string = "hidebackbutton";
    static token: string = "token";
    static phoneNumber: string = "phonenumber";
    static triggerAggregation: string = "triggeraggregation";
    static currency: string = "currency";
    static clientUserId: string = "clientuserid";
    static extrafield1: string = "extrafield1"
    static extrafield2: string = "extrafield2"
    static creditCardAddressLine1: string = "creditcardaddressline1"
    static creditCardAddressLine2: string = "creditcardaddressline2"
    static creditCardAddressCity: string = "creditcardaddresscity"
    static creditCardAddressPostalCode: string = "creditcardaddresspostalcode"
    static creditCardAddressCountry: string = "creditcardaddresscountry"
    static creditCardAddressProvince: string = "creditcardaddressprovince"
    static displayTermsAndConditions: string = "displaytermsandconditions"
    static sdkredirect: string = "sdkredirect"
    static businessTaxId: string = "businesstaxid"
    static idType: string = "idtype"
    static idNumber: string = "idnumber"
    static idState: string = "idstate"
    static billingAddressLine1: string = "billingaddressline1"
    static billingAddressLine2: string = "billingaddressline2"
    static billingAddressCity: string = "billingaddresscity"
    static billingAddressProvince: string = "billingaddressprovince"
    static billingAddressPostalCode: string = "billingaddresspostalcode"
    static singleAccountSelection: string = "singleaccountselection"
    static removeLogo: string = "removelogo"
    static reduceSpacing: string = "reducespacing"
    static addCancelButton: string = "addcancelbutton"
    static smallButtons: string = "smallbuttons"
    static displayBankConnection: string = "displaybankconnection"
    static displayPostalCodeOnly: string = "displaypostalcodeonly"
    static tecUrl: string = "tecurl"
}

export class OptionalQueryParams {

    firstName?: string;
    lastName?: string;
    accountType?: string;
    email?: string;
    companyName?: string;
    memo?: string;
    comment?: string;
    allowEft?: string;
    allowInterac?: string;
    allowCreditCard?: string;
    allowVisaDirect?: string;
    allowAch?: string;
    displayTermsAndCondition?: boolean;
    displayZumRailsLogo?: boolean;
    displayDetailsColumn?: boolean;
    displayPhoneNumber?: boolean = true;
    displayFullName?: boolean;
    displayAccountType?: boolean = true;
    hideRailsName?: boolean;
    backgroundColor?: string;
    disablePreFilledFields?: boolean;
    language?: string;
    hideFirstName?: boolean;
    hideLastName?: boolean;
    hideFullName?: boolean;
    hideEmail?: boolean;
    hideCreditCardAddress?: boolean;
    hideShippingAddress?: boolean;
    brandColor?: string;
    foregroundColor?: string;
    accentColor?: string;
    customerName?: string;
    amount?: number;
    showMethodSelector?: boolean;
    creditCardAddresLine1?: string;
    creditCardAddresLine2?: string;
    creditCardCity?: string;
    creditCardProvince?: string;
    creditCardPostalCode?: string;
    creditCardCountry?: string;
    hideBackButton?: boolean;
    token?: string;
    phoneNumber?: string;
    triggerAggregation?: boolean;
    currency?: Currency;
    clientUserId?: string;
    extrafield1?: string;
    extrafield2?: string;
    creditCardAddressLine1?:string;
    creditCardAddressLine2?:string;
    creditCardAddressCity?:string;
    creditCardAddressPostalCode?:string;
    creditCardAddressProvince?:string;
    creditCardAddressCountry?:string;
    displayTermsAndConditions?:boolean;
    sdkredirect?:boolean;
    businessTaxId?: string;
    idType?: string;
    idNumber?: string;
    idState?: string;
    billingAddressLine1?: string;
    billingAddressLine2?: string;
    billingAddressCity?: string;
    billingAddressProvince?: string;
    billingAddressPostalCode?: string;
    singleAccountSelection?: string;
    removeLogo?: boolean;
    reduceSpacing?: boolean;
    addCancelButton?: boolean;
    smallButtons?: boolean;
    displayBankConnection?: string;
    displayPostalCodeOnly?: boolean;
    //Used to pass an overriding link for the Terms and Conditions on payment forms
    //Created to continue the pattern of 'tecUrl' on aggregation side 
    //Will be updated to "termsAndConditionsUrl" on AP-3679
    tecUrl?: boolean;

    get nameOnCard(): string | undefined {
        if(this.firstName == null && this.companyName == null)
        {
            return undefined;
        }

        if(this.firstName && this.displayFullName)
        {
            return `${this.firstName}`;
        }

        if(this.firstName)
        {
            return `${this.firstName} ${this.lastName ?? ''}`;
        }

        return this.companyName;
    }

    constructor(optionalQueryParams: Partial<OptionalQueryParams>) {
        Object.assign(this, optionalQueryParams);

        this.accountType = this.accountType || "Personal";
        if(this.accountType?.toLowerCase() == "personal") {
            this.accountType = AccountType.Personal;
            return;
        }

        if(this.accountType?.toLowerCase() == "business") {
            this.accountType = AccountType.Business;
        }
    }
}
