import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';


@Injectable()
export class ValidatorsHelper {

    constructor(
        @Inject(DOCUMENT) private _document: any) {
    }

    private spaceSeparator = /\s+/gmu;

    range(min: number, max: number): ValidatorFn{
        return (c: AbstractControl) : {[key: string] : boolean} | null =>{
            if(c.value !== null && (isNaN(c.value) || c.value < min || c.value > max)){
                return {'range' : true};
            }
            return null;
        };
    }

    greaterThan(number: number, includeNumber?: boolean): ValidatorFn{
        return (c: AbstractControl) : {[key: string] : boolean} | null =>{
            if(!includeNumber){
                if(c.value !== null && (isNaN(c.value) || number >= c.value )){
                    return {'greaterthan' : true};
                }
            }
            else{
                if(c.value !== null && (isNaN(c.value) || number > c.value )){
                    return {'greaterthan' : true};
                }
            }

            return null;
        };
    }

    minWord(min: number, separator: string | RegExp = this.spaceSeparator): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value && typeof control.value === 'string') {
                const value = control.value.trim();
                if (!value) {
                    return { minword: { min } };
                }
                const words = value.split(separator);
                const actual = words.length;
                if (actual < min) {
                    return { minword: { min, actual } };
                }
            }
            return null;
        };
    }

}
