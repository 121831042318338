import {Component, Inject} from "@angular/core";
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
import {DialogData} from "../connect-wrapper/connect-wrapper.component";

@Component({
    selector: 'pad-dialog-v2',
    templateUrl: 'pad-dialog-v2.component.html',
    styleUrls: ["./pad-dialog-v2.component.scss"],
})

export class PadDialogV2 {

    customerName: string
    customerLegalName: string;
    isUs: boolean

    constructor(
        public dialogRef: MatDialogRef<PadDialogV2>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {
        this.customerName = data.companyName
        this.customerLegalName = data.companyLegalName
        this.isUs = data.isUs
    }

    onActionClick(agree): void {
        this.data.agree = agree;
        this.dialogRef.close(this.data);
    }
}