<b class="uppercase">Terms of service</b>
<p></p>
<p class="capitalize">Last Updated: February 5, 2024</p>

<p>Your use of the Connect service enabled by Zūm Rails Inc. (“Zūm Rails”) from within the {{ customerName }} website
    (the
    “Website”) is subject to the following terms. These Connect Terms of Connect service (“Terms of Connect service” are
    a legal agreement between {{ customerLegalName }} (“{{ customerName }}”, “we” or “us”) and you, the user of the Connect
    service
    (“you”), and govern your use of the Connect service and related matters. By using the Connect service, you agree to
    be bound by these Terms of Connect service, as amended by {{ customerName }} from time to time, as well as to the
    {{ customerName }} Privacy Policy. You also agree that if you are accepting these terms on behalf of another entity,
    you
    have the full authorization to do so.</p>

<p class="font-bold">1. General</p>
<p>We use the Connect service to allow you to connect your bank account, credit card or debit card (“Payment Method”).
    Connecting your Payment Method helps us better support your requested payments, including by verifying your account
    and accelerating your payments where possible.</p>

<p class="font-bold">2. Using the Connect service</p>
<p>If you choose to enable the Connect service, Zūm Rails will ask you to connect Payment Method. If you connect your
    bank account, you will provide your bank credentials (including your user name and password) to Zūm Rails’ (“Connect
    service Provider”), who will only use your credentials to retrieve bank account data for your requested
    transactions, including the following:</p>
<ul>
    <li>Contact information (such as your name, email address, phone number, and address)</li>
    <li>Account details (such as your account type and bank, branch, and account numbers)</li>
</ul>

<p>
    What is collected from your account will vary depending in part on what your bank makes available. Zūm Rails will
    then store and use this information to better support your future requested payments with us, including by verifying
    your bank account and accelerating your payments where possible.
</p>

<p>
    Zūm Rails will also transfer to its banking partner the account details in order to debit your account at the time
    of your requested payments. By connecting your Payment Method, you also provide Zūm Rails with the authority to
    share certain account details with us to support your customer relationship with us, such as information for record
    keeping.
</p>

<p>
    The API, or similar Connect service, is an essential component of the Connect service and you cannot proceed with
    the Connect service unless you agree to use the API or similar Connect service. In the event the Zūm Rails API is
    unavailable, you agree that Zūm Rails will be entitled to select a replacement Connect service Provider for the time
    period in which the Zūm Rails API is unavailable. Zūm Rail will be solely liable to you for any damages, losses, or
    otherwise resulting from its use of a replacement Connect service Provider.
</p>

<p class="font-bold">3. Link or Unlink a Payment Method</p>
<p>
    Before linking a bank account, you should review and understand the consumer protection rights and remedies
    available for different payment sources under the Electronic Fund Transfer Act (EFTA) and Fair Credit Billing Act
    (FCBA). Please keep your payment method information current (e.g., credit card number and expiration date). If this
    information changes, we may update it using information and third-party sources available to us without any action
    on your part.
</p>

<p class="font-bold">4. Data Privacy</p>
<p>
    By using the Connect service, you consent to the collection, use, disclosure, and retention of your personal data.
    You confirm that the information you are providing to Zūm Rails has no mistakes and is your correct information. Zūm
    Rails has its own privacy policy and for more information, please visit their website(
    <a href="https://zumrails.com/privacy-policy" target="_blank">https://zumrails.com/privacy-policy</a> ).
    Neither {{ customerName }} nor Zūm Rails will sell or rent your personal data. Zum
    Rails may use your data in an anonymized manner (without identifying you) for any purpose permitted under applicable
    law. Zūm Rails is not responsible or liable for the use, disclosure, or retention of your personal data by
    {{ customerName }} and {{ customerName }} is not responsible or liable for the use, disclosure, or retention of your
    personal
    data by Zūm Rails.
</p>

<p>
    If you would like to exercise any of your privacy rights, please contact {{ customerName }} directly. Please contact
    {{ customerName }} regarding inquiries concerning canceling or stopping payments.
</p>

<p>
    Where shopper’s cardholder payment data is transmitted, processed, or stored through the the Connect service, Zum
    Rails acknowledges that it shall be responsible for the security of such data in accordance with applicable PCI DSS
    requirements.
</p>

<p>
    Zum Rails routinely backs up transaction information on its servers in Canada and USA and by using the Zum Rails
    services you agree for relevant transaction information to be stored on such servers and agree to the
    transfer/export of data from the country or territory from where you are conducting the transaction to our data
    servers.
</p>

<p class="font-bold">5. Grant of Power of Attorney</p>
<p>
    You grant Zūm Rails a limited power of attorney, and you appoint Zūm Rails as your attorney-in-fact and agent in all
    capacities to access your selected account(s), retrieve and store information as described above, to provide you the
    Connect service and as permitted by applicable laws and regulations. You agree that when Zūm Rails accesses and
    retrieves your information from your selected account(s), Zūm Rails is acting as your agent, and not the agent of
    any third-party banks. You agree that third-party banks shall be entitled to rely on the foregoing authorization,
    agency, and power of attorney granted by you. You understand and agree that the Connect service is not endorsed or
    sponsored by any third party whose account(s) are accessible through the Connect service. The power of attorney that
    you grant Zūm Rails may be revoked at any time by deleting the information you shared with Zūm Rails, as the case
    may be, or by otherwise discontinuing use of this Connect service.
</p>

<p class="font-bold">6. Payment Methods and the Connect service</p>
<p>
    If you are using the Connect service in conjunction with the Payment Methods, you are not permitted to initiate or
    receive any third-party transactions without prior authorization from Zum Rails. We reserve the right to terminate,
    suspend or revoke access to the Connect service if you engage in such transactions. We also may close your account.
</p>

<p class="font-bold">7. Zūm Rails' Role</p>
<p>
    The Connect service is a way of verifying and storing Payment Methods, and facilitating payments
    for {{ customerName }},
    but it does not change anything else about your relationship with {{ customerName }} or your bank or financial
    institution. You are ultimately responsible for complying with the agreements that govern your relationship with
    {{ customerName }}, your relationship with your bank or financial institution accounts, and that govern the
    purchases you
    make using the Connect service. Also, {{ customerName }} is the one that is responsible for making the deposited
    funds
    available in the selected {{ customerName }} account when you use the Connect service. Zūm Rails uses reasonable
    efforts
    to keep your financial data secure.
</p>

<p class="font-bold">8. Proprietary Rights</p>
<p>
    Using the Connect service does not grant you any intellectual property, proprietary, or other rights to the Connect
    service. Furthermore, you agree not to reverse engineer, disassemble, decompile, derive or otherwise transform, or
    attempt any of the foregoing, any of the technology used to provide the Connect service, whether owned or licensed
    by {{ customerName }}, Zūm Rails, or any of its Connect service Providers.
</p>

<p class="font-bold">9. Representations and Warranties</p>

<p>For Individuals:</p>
<p>By using the Connect service you represent and warrant that you:</p>
<ol>
    <li> <b>a.</b> Are at least the 18 years of age;</li>
    <li> <b>b.</b> Will not use the Connect service for any fraudulent, unlawful or abusive purpose;</li>
    <li> <b>c.</b> You have the lawful right to authorize Zūm Rails to access your bank account, or your company’s bank account, as
        the case may be, and to use your financial data.
    </li>
</ol>

<p>For Sole Proprietorships, Partnerships and Corporations:</p>
<p>By using the Connect service you represent and warrant that you:</p>
<ol>
    <li> <b>a.</b> it is a sole proprietorship/partnership/corporation, duly organized, validly existing and in good standing;</li>
    <li> <b>b.</b> it has the full right, power and authority to enter into these terms;</li>
    <li> <b>c.</b> your acceptance of these terms have been duly authorized by all necessary action;</li>
    <li> <b>d.</b> The acceptance of these terms will not violate, conflict with, require consent under, or result in any breach or
        default under (i) any of its organizational documents (including its partnership agreement, its certificate of
        incorporation and by-laws, as applicable), (ii) any applicable laws or (iii) the provisions of any contract or
        agreement to which it is a party or to which any of its material assets are bound.
    </li>
</ol>

<p class="font-bold">10. Disclaimers</p>
<p>
    The Connect service, including all content, software, functions, materials, and information made available on,
    provided in connection with or accessible through the Connect service, are provided “as is” and “as available”. To
    the fullest extent permissible by law, neither {{ customerName }}, Zūm Rails, nor their affiliates, and their
    agents,
    merchants or independent contractors and Connect service providers (the “Disclaiming Entities”), make any
    representation, warranty, condition or guarantee, of any nature or kind whatsoever, whether express, implied or
    statutory, for the Connect services or the content, materials, information, and functions made accessible by the
    Connect service, including without limitation the timing of delivery of funds, whether or not the timing was in the
    control of {{ customerName }} or Zūm Rails, or for any breach of security associated with the transmission of
    sensitive
    information through the Connect service. Each Disclaiming Entity disclaims without limitation, any representation,
    warranty, condition, or guarantee of any nature or kind with respect to the Connect service, non-infringement,
    merchantability, or fitness for a particular purpose. The Disclaiming Entities do not warrant that the functions
    contained in the Connect service will be uninterrupted or error-free. The Disclaiming Entities shall not be
    responsible for any Connect service interruptions, including, but not limited to, system failures or other
    interruptions that may affect the receipt, processing, acceptance, completion, or settlement of payment
    transactions. Notwithstanding anything to the contrary in these Terms of Connect service, you agree that you are
    using the Connect service at your own discretion and at your own risk, and you are solely responsible for any damage
    to, or loss of information from your computer or mobile device, that results from the download of any Data. No
    advice or information, whether oral or written, obtained by you from {{ customerName }} or Zūm Rails through or from
    the
    Connect service will create any representation, warranty, condition or guarantee not expressly stated in these Terms
    of Connect service. Some jurisdictions do not allow the exclusion of certain warranties, so some of the above
    exclusions may not apply to you.
</p>

<p class="font-bold">11. Limitations of Liability; Force Majeure</p>
<p>
    To the maximum extent permitted by applicable law, in no event, and under no circumstances shall any Disclaiming
    Entity be responsible or liable to you or any third party under any circumstances for any indirect, consequential,
    special, punitive or exemplary, damages or losses, including but not limited to damages for loss of profits,
    goodwill, use, data, or other intangible losses, which may be incurred in connection with the Connect service, any
    Disclaiming Entity, or arising from or relating to these Terms of Connect service, any goods, Connect services, or
    information purchased, received, sold, or paid for and facilitated by the Connect service, unauthorized access to or
    alteration of your transmissions or data, or any other matter relating to the Connect service. This section 10 is a
    comprehensive exclusion and limitation of liability and applies to liability under any theory (including contract,
    tort, strict liability, and statutory liability), regardless of any negligence or other fault or wrongdoing
    (including fundamental breach or gross negligence) by or on behalf of any Disclaiming Entity or any of its
    personnel, even if other remedies are not available or do not adequately compensate for the loss or damage, even if
    a Disclaiming Entity knows or ought to have known of the possibility of the loss or damage being incurred, and
    regardless of whether or not the loss or damage was foreseeable. Without limiting the foregoing, and to the maximum
    extent permitted by applicable law, in no event, and under no circumstances shall the Disclaiming Entities’ total
    cumulative liability related to the Connect service, any Disclaiming Entity, or arising from or relating to these
    Terms of Connect service exceed $10 CAD dollars. Each party acknowledges that the other party has entered into these
    Terms of Connect service relying on the limitations of liability stated herein and that those limitations are an
    essential basis of the bargain between the parties. In addition to and without limiting any of the foregoing, no
    Disclaiming Entity shall be liable or responsible to you, nor be deemed to have defaulted under or breached these
    Terms of Connect service, for any failure or delay in fulfilling or performing any term of these Terms of Connect
    service, when and to the extent such failure or delay is caused by or results from acts beyond the Disclaiming
    Entity’s reasonable control, including, without limitation, the following force majeure events (“Force Majeure
    Event(s)”): (a) acts of God; (b) flood, fire, earthquake, tsunami, epidemics, pandemics including the 2019 novel
    coronavirus pandemic (COVID-19), or explosion; (c) war, invasion, hostilities (whether war is declared or not),
    terrorist threats or acts, riot, or other civil unrest; (d) government order or law; (e) actions, embargoes, or
    blockades in effect on or after the date of this Agreement; (f) action by any governmental authority; (g) national
    or regional emergency; (h) strikes, labour stoppages or slowdowns, or other industrial disturbances; (i) shortage of
    adequate power or transportation facilities; and (j) other similar events beyond the reasonable control of the
    Impacted Party.
</p>

<p class="font-bold">12. Governing Law and Disputes</p>
<p>
    These Terms of Connect service, the subject matter of these Terms of Connect service (including the Connect
    service), and all related matters will be governed by the laws of the State of Florida, excluding any rules
    regarding conflict of laws that would lead to the application of any other laws.
</p>

<p>
    These Terms of Connect service shall not be governed by the United Nations Convention on Contracts for the
    International Sale of Goods. Except to the extent prohibited by applicable law, all disputes arising from, connected
    with, or relating to these Terms of Connect service, the subject matter of these Terms of Connect service (including
    the Connect service) or any related matter will be resolved before the courts of Florida sitting in the City of
    Miami, and the parties each hereby irrevocably submit and attorn to the jurisdiction of those courts in respect of
    those matters.
</p>

<p class="font-bold">13. Modification of Terms of Connect service; Notices</p>
<p>
    We have the right to change or add to these Terms of Connect service at any time, solely with prospective effect,
    and to change, delete, discontinue, or impose conditions on the use of the Connect service by posting such changes
    on our website or any other website we maintain or we own. We may provide you with notice via email, postings on our
    website, or through other reasonable means. If you are an existing user of the Connect service, the changes will
    come into effect as of the effective date indicated on the notice, and your use of the Connect service after we
    notify you of any such changes constitutes your acceptance of the modified Terms of Connect service. In the event
    that you do not agree with any such modification, your sole and exclusive remedy are to terminate your use of the
    Connect service. You can access a copy of the current terms of these Terms of Connect service on our website at any
    time. You can find out when these Terms of Connect service were last changed by checking the “Last updated” date at
    the top of these Terms of Connect service.
</p>

<p class="font-bold">14. Assignment</p>
<p>
    You may not assign these Terms of Connect service or any rights or obligations hereunder, by operation of law or
    otherwise, without our prior written approval and any such attempted assignment shall be void. We reserve the right
    to freely assign these Terms of Connect service and the rights and obligations hereunder to any third party without
    your consent or any notice to you.
</p>
<p>
    Subject to the foregoing, these Terms of Connect service shall be binding upon and inure to the benefit of the
    parties hereto, their respective heirs, executors, personal representatives, successors, and permitted assigns.
</p>

<p class="font-bold">15. Termination, Suspension, or Revocation of Access</p>
<p>
    At any time, {{ customerName }} may terminate these Terms of Connect service and suspend or revoke your access to
    the
    Connect service with or without notice and for any or no reason, including in the event of a suspected, attempted,
    or confirmed breach of your obligations pursuant to these Terms of Connect service.
</p>

<p class="font-bold">16. Survival</p>
<p>Upon termination of your use of the Connect service or termination of these Terms of Connect service for any reason,
    in addition to this section, the following sections shall survive termination: Sections 6 through 16.</p>

<p class="font-bold">17. Miscellaneous</p>
<p>
    {{ customerName }}’s failure to exercise or enforce any right or provision of these Terms of Connect service will
    not be
    considered a waiver of that right or provision. If any provision of these Terms of Connect service is held by any
    court of competent jurisdiction to be unenforceable or invalid for any reason, then that provision will be deemed
    severed from these Terms of Connect service and the remaining provisions will continue in full force and effect and
    remain binding and enforceable between the parties unless as a result of the severance these Terms of Connect
    service would fail in their essential purpose. Headings are for reference purposes only and in no way define, limit,
    construe or describe the scope or extent of such section. These Terms of Connect service constitute the entire
    agreement between you and {{ customerName }} with respect to the use of the Connect service. These Terms of Connect
    service describe the entire liability as between you and {{ customerName }}, and set forth your exclusive remedies,
    with
    respect to the Connect service. These Terms of Connect service are not intended and shall not be construed to create
    any rights or remedies in any parties other than you, {{ customerName }}, and Zūm Rails, and no other person will
    have the
    ability to assert any rights as a third-party beneficiary under these Terms of Connect service.
</p>
<p>
    No changes may be made to these Terms of Connect service except in accordance with Section 13. These Terms of
    Connect service do not limit any rights that {{ customerName }} or Zūm Rails may have under trade secret, copyright,
    patent, or other intellectual property laws. The parties have expressly requested and required that these Terms of
    Connect service and all related documents be written in the English language. Les parties conviennent et exigent
    expressément que ce contrat et tous les documents qui s’y rapportent soient rédigés en anglais.
</p>