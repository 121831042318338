import { NgModule } from '@angular/core';
import { FeatherModule } from 'angular-feather';
import { 
  CreditCard,
  Send,
  ArrowUpCircle, 
  Lock, 
  CheckCircle, 
  XCircle, 
  AlertCircle, 
  Mail, 
  Clock, 
  Layout, 
  ArrowLeft, 
  ArrowRight, 
  User, 
  Shield, 
  FileText,
  Download,
  Eye,
  File,
  LogOut,
} from 'angular-feather/icons';

const icons = {
    CreditCard,
    Send,
    ArrowUpCircle,
    Lock,
    CheckCircle,
    XCircle,
    AlertCircle,
    Mail,
    Clock,
    Layout,
    ArrowLeft,
    ArrowRight,
    User,
    Shield,
    FileText,
    Download,
    Eye,
    File,
    LogOut,
};

@NgModule({
  imports: [
    FeatherModule.pick(icons)
  ],
  exports: [
    FeatherModule
  ]
})

export class IconsModule { }