import {NgModule} from '@angular/core';
import {Route, RouterModule, Routes} from '@angular/router';
import {environment} from "../environments/environment";

let routes: Routes = [
    // questrade
    {
        path: 'institution-list',
        loadChildren: () => import('./pages/questrade/institution-list/institution-list.modules').then(m => m.InstitutionListModule)
    },
    {
        path: 'aggregation-login',
        loadChildren: () => import('./pages/questrade/aggregation/aggregation-login/aggregation-login.module').then(m => m.AggregationLoginModule)
    },
    {
        path: 'aggregation-mfa',
        loadChildren: () => import('./pages/questrade/aggregation/aggregation-mfa/aggregation-mfa.module').then(m => m.AggregationMfaModule)
    },
    {
        path: 'visa-direct',
        loadChildren: () => import('./pages/questrade/visa-direct/visa-direct.module').then(m => m.VisaDirectModule)
    },
    {
        path: 'error',
        loadChildren: () => import('./pages/questrade/error/error.module').then(m => m.ErrorModule)
    },

    // connect
    {
        path: 'connect/:customerId',
        loadChildren: () => import('./pages/connect/connect-wrapper/connect-wrapper.module').then(m => m.ConnectWrapperModule)
    },
    {
        path: 'connect/fundingsource/:customerId',
        loadChildren: () => import('./pages/connect/connect-wrapper/connect-wrapper.module').then(m => m.ConnectWrapperModule)
    },
    {
        path: 'connect/:customerId/:requestFundsId',
        loadChildren: () => import('./pages/connect/connect-wrapper/connect-wrapper.module').then(m => m.ConnectWrapperModule)
    },

    // new iframe
    {
        path: 'preview/connect',
        loadChildren: () => import('./pages/iframe/add-user-wrapper-preview/add-user-wrapper-preview.module').then(m => m.AddUserWrapperPreviewModule)
    },
    {
        path: 'invoice/:invoiceId',
        loadChildren: () => import('./pages/iframe/invoice/invoice.module').then(m => m.InvoiceModule)
    },
    {
        path: 'receipt/:invoiceId',
        loadChildren: () => import('./pages/iframe/receipt/receipt.module').then(m => m.ReceiptModule)
    },
    {
        path: 'preview/invoice',
        loadChildren: () => import('./pages/iframe/invoice/invoice.module').then(m => m.InvoiceModule)
    },
    {
        path: 'subscription/:subscriptionId',
        loadChildren: () => import('./pages/iframe/subscription/subscription.module').then(m => m.SubscriptionModule)
    },
    {
        path: 'subscription/update/:subscriptionId',
        loadChildren: () => import('./pages/iframe/subscription-update/subscription-update.module').then(m => m.SubscriptionUpdateModule)
    },
    {
        path: 'subscription/cancel/:subscriptionId',
        loadChildren: () => import('./pages/iframe/subscription-cancel/subscription-cancel.module').then(m => m.SubscriptionCancelModule),
    },
    {
        path: 'userportal/:customerId',
        loadChildren: () => import('./pages/iframe/user-portal/user-portal.module').then(m => m.UserPortalModule)
    },
    {
        path: 'connect-adduser/:customerId',
        loadChildren: () => import('./pages/iframe/add-user-wrapper/add-user-wrapper.module').then(m => m.AddUserWrapperModule)
    },
    {
        path: 'product-price/:productPriceId',
        loadChildren: () => import('./pages/iframe/product-price-checkout-wrapper/product-price-checkout-wrapper.module').then(m => m.ProductPriceCheckoutWrapperModule)
    },
    {
        path: 'product-price/:productPriceId/v2',
        loadChildren: () => import('./pages/iframe/product-price-checkout-wrapper-v2/product-price-checkout-wrapper-v2.module').then(m => m.ProductPriceCheckoutWrapperV2Module)
    },
    {
        path: 'product/:productId',
        loadChildren: () => import('./pages/iframe/product-checkout/product-checkout.modules').then(m => m.ProductCheckoutWrapperModule)
    },
    {
        path: 'product/:productId/v2',
        loadChildren: () => import('./pages/iframe/product-checkout-v2/product-checkout-v2.modules').then(m => m.ProductCheckoutWrapperV2Module)
    },    
    {
        path: 'invoice/pay/:invoiceId',
        loadChildren: () => import('./pages/iframe/invoice-wrapper/invoice-wrapper.module').then(m => m.InvoiceWrapperModule)
    },
    {
        path: 'preview/invoice/pay',
        loadChildren: () => import('./pages/iframe/invoice-wrapper-preview/invoice-wrapper-preview.module').then(m => m.InvoiceWrapperPreviewModule)
    },
    //v2
    {
        path: 'preview/connect/v2',
        loadChildren: () => import('./pages/iframe/add-user-wrapper-preview/add-user-wrapper-preview.module-v2').then(m => m.AddUserWrapperPreviewModuleV2)
    },
    {
        path: 'connect-adduser/:customerId/v2',
        loadChildren: () => import('./pages/iframe/add-user-wrapper/add-user-wrapper-v2.module').then(m => m.AddUserWrapperV2Module)
    },
    {
        path: 'oauth-in-progress',
        loadChildren: () => import('./pages/iframe/oauth-in-progress/oauth-in-progress.module').then(m => m.OauthInProgressModule)
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})

export class AppRoutingModule {
}
