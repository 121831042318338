<div
  class="flex flex-col items-center justify-center text-center md:w-full xl:w-full 2xl: w-full"
>
  <i-feather
    name="{{ iconSymbol }}"
    [ngClass]="iconColorClass"
    class="icon-size fill-current mb-2"
  ></i-feather>
  <span class="error-text">{{ errorMessage }}</span>
  <div *ngIf="showButton" class="w-full text-center pt-1 mt-2">
    <button
      (click)="handleButtonClick()"
      class="button btn-outline-dark w-48"
      type="submit"
      mat-raised-button
    >
      <span class="center-text-flex">{{ buttonText }}</span>
      <i-feather class="ml-6" name="arrow-left"></i-feather>
    </button>
  </div>
</div>
