export enum TransactionMethod {
    Eft = 'Eft',
    Interac = 'Interac',
    CreditCard = 'CreditCard',
    CreditCardUs = 'CreditCardUs',
    VisaDirect = 'VisaDirect',
    VisaDirectPull = 'VisaDirectPull',
    VisaDirectPush = 'VisaDirectPush',
    Ach = 'Ach'
}

export enum TransactionType {
    Debit = 'Debit',
    Credit = 'Credit'
}

export class TransactionMethodsAvailable {
    Eft: boolean;
    Interac: boolean;
    CreditCard: boolean;
    VisaDirectPull: boolean;
    VisaDirectPush: boolean;
}

export class UserTransactionMethodAvailable {
    Method: TransactionMethod;
    Available: boolean;
}
