import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-invoice-iframe-error",
    templateUrl: "./error.component.html",
    styleUrls: ["./error.component.scss"],
})
export class InvoiceErrorComponent implements OnInit {
    @Input() errorMessage: string;
    @Input() showButton: boolean = false;
    @Input() buttonText: string;
    @Input() iconColorClass: string = "text-red-600";
    @Input() iconSymbol: string = "x-circle";
    @Output() onButtonClick = new EventEmitter<boolean>();

    constructor(private translate: TranslateService) {}

    handleButtonClick() {
        this.onButtonClick.emit(true);
    }

    ngOnInit() {
        if (!this.errorMessage) {
            this.errorMessage = this.translate.instant("LABEL.ERROR");
        }
        if (!this.buttonText) {
            this.buttonText = this.translate.instant(
                "INVOICE.TEXT.RETRYPAYMENT"
            );
        }
    }
}
