export enum ConnectErrorCode {
    AggregationInvalidCredentials = 'AggregationInvalidCredentials',
    AggregationInvalidMfaAnswer = 'AggregationInvalidMfaAnswer',
    AggregationFailedToLinkCard = 'AggregationFailedToLinkCard',
    AggregationFailedToFind1Cent = 'AggregationFailedToFind1Cent',
    AggregationFailedToValidateName = 'AggregationFailedToValidateName',
    VisaDirectDeclined1Cent = 'VisaDirectDeclined1Cent',
    VisaDirectNotDebitCard = 'VisaDirectNotDebitCard',
    VisaDirectNotCADCurrency = 'VisaDirectNotCADCurrency',
    VisaDirectNotCanadian = 'VisaDirectNotCanadian',
    VisaDirectPullNotEnabled = 'VisaDirectPullNotEnabled',
    VisaDirectRejectedAccountLimitExceeded = 'VisaDirectRejectedAccountLimitExceeded',
    WeekendNotAcceptedInstitution = 'WeekendNotAcceptedInstitution',
    TokenExpired = 'TokenExpired',
    Generic = 'Generic',
    RequestServerError = 'Request server error',
    ThreeDSAuthenticationError = 'ThreeDSAuthenticationError',
    ThreeDSChallengeError = 'ThreeDSChallengeError',
    ThreeDSGenericError = 'ThreeDSGenericError',
    BankAchAddUserError = 'BankAchAddUserError'
}