import { Component } from "@angular/core";
import { CountryType } from "src/app/models/CountryType";
import { TransactionMethod } from "src/app/models/TransactionMethod";
import { ConnectingFlow, OptionalQueryParamsService } from "src/app/services/optional-query-params.service";
import { isUsCustomer } from "src/app/services/customer.service";
import { Customer } from "src/app/models/Customer";

@Component({
    template: "",
})
export abstract class OptionalParamsConsumer  {
    public flow: ConnectingFlow = "AddingPaymentProfile";
    public countryType: CountryType = null;
    public transactionMethod: TransactionMethod = null;

    constructor(
        private optionalQueryParamsService: OptionalQueryParamsService
    ) {
        this.optionalQueryParamsService.digestRawParams();
    }

    public digestParams(
        countryType: CountryType,
        transactionMethod: TransactionMethod,
        connectingFlow: ConnectingFlow
    ) {
        this.flow = connectingFlow;
        this.countryType = countryType;
        this.transactionMethod = transactionMethod;
        this.optionalQueryParamsService.digestConditionalParams(
            countryType,
            connectingFlow,
            transactionMethod
        );
    }

    get optionalParams() {
        return this.optionalQueryParamsService.optionalParams;
    }

    get showBillingAddress(): boolean {
        return this.optionalParams.hideCreditCardAddress !== true;
    }

    get showShippingAddress(): boolean {
        return this.optionalParams.hideShippingAddress !== true;
    }

    get shouldDisplayPostalCodeOnly () {
        return this.optionalParams?.displayPostalCodeOnly && 
                !this.optionalParams.hideCreditCardAddress;
    }

    get showUseBillingAddressAsShippingAddressToggle(): boolean {
        return this.showBillingAddress && this.showShippingAddress;
    }

    get showTermsAndConditions(): boolean {
        let isUs = this.countryType == "USA"
        if (isUs) {
            return this.optionalParams?.displayTermsAndConditions
        }
        return this.optionalParams?.displayTermsAndCondition
    }
}
