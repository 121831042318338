<div class="w-full text-right">
    <i class="mdi mdi-close text-gray-700 text-xl cursor-pointer" (click)="onActionClick(null)"></i>
</div>
<div class="text-sm text-terms-and-conditions flex flex-col pr-2 w-full list overflow-y-auto overflow-x-hidden" id="padDialogModal">
    <div style="height: 30rem;">
        <tc-text-ca *ngIf="!isUs" [customerName]="customerName"></tc-text-ca>
        <tc-text-us *ngIf="isUs" [customerName]="customerName" [customerLegalName]="customerLegalName"></tc-text-us>
        <div class="w-full mt-4 mb-2 bottom-0 flex content-center text-right justify-between relative">
            <button
                    class="hover:bg-gray-100 w-full button border rounded accent-color focus:outline-non"
                    (click)="onActionClick(false)"><span
                    class="center-text-flex">{{ 'LABEL.NOTHANKS' | translate }}</span>
            </button>
            <button class="button button-company-color button-text-color w-full h-auto ml-1 focus:outline-none"
                    (click)="onActionClick(true)"><span class="center-text-flex">{{ 'LABEL.IAGRRE' | translate }}</span>
            </button>
        </div>
    </div>
</div>

