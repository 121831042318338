import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {CurrencyPipe, DatePipe, CommonModule} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {PageTitleService} from './services/page-title.service';
import {TokenService} from './services/token.service';
import {EventListenerService} from './services/event-listener.service';
import {LoadingService} from './services/loading.service';
import {LoadingComponent} from './pages/questrade/loading/loading.component';
import {PublicService} from './services/public.service';
import {PadDialog} from './pages/connect/pad-dialog/pad-dialog.component';
import {AmountService} from './pages/connect/connect-wrapper/services/amount.service';
import {GlobalHelper} from './helpers/GlobalHelper';
import {ValidatorsHelper} from './helpers/ValidatorsHelper';
import {GlobalModule} from './app.global.module';
import {TermsConditionsService} from './services/terms-conditions.service';
import {NoopAnimationsModule} from '@angular/platform-browser/animations';
import {PaymentProfileService} from './services/payment-profile.service';
import {PadDialogV2} from "./pages/connect/pad-dialog/pad-dialog-v2.component";
import {TCTextCaComponent} from "./pages/connect/pad-dialog/text-v2/tc-text-ca.component";
import {TCTextUsComponent} from "./pages/connect/pad-dialog/text-v2/tc-text-us.component";
import { MaterialModule } from './material/material.module';
import { StylesBehaviorModule } from './components/force-version-two-styles/styles-behavior.module';

@NgModule({
    declarations: [
        AppComponent,
        LoadingComponent,
        PadDialogV2,
        PadDialog,
        TCTextCaComponent,
        TCTextUsComponent,
    ],
    imports: [
        GlobalModule,
        HttpClientModule,
        BrowserModule,
        AppRoutingModule,
        NoopAnimationsModule,
        MaterialModule,
        StylesBehaviorModule
    ],
    providers: [
        TokenService,
        PageTitleService,
        EventListenerService,
        LoadingService,
        TermsConditionsService,
        PublicService,
        AmountService,
        GlobalHelper,
        CommonModule,
        CurrencyPipe,
        DatePipe,
        ValidatorsHelper,
        PaymentProfileService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
