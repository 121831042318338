export enum EventListenerStep {
    INTERACCLICKED = 'INTERACCLICKED',
    CONNECTLOADED = 'CONNECTLOADED',
    CONNECTCLOSED = 'CONNECTCLOSED',
    PRICESELECTED = 'PRICESELECTED',
    QUANTITYSELECTED = 'QUANTITYSELECTED',
    BILLINGDAYSELECTED = 'BILLINGDAYSELECTED',
    PAYMENTMETHODSELECTED = 'PAYMENTMETHODSELECTED',
    BUTTONCLICKED = 'BUTTONCLICKED',
    CONNECTCOMPLETED = 'CONNECTCOMPLETED',
    CONNECTFAILED = 'CONNECTFAILED',
}