import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { FundingSourceIngestFromAggregationInputDTO } from "../models/Aggregation";
import { ApiResponse } from '../models/ApiResponse';
import { BankAccountInformation } from "../models/BankAccountInformation";
import { CompleteTransactionOutputDTO } from "../models/CompleteTransactionOutputDTO";
import { Customer, CustomerCustomConfiguration } from '../models/Customer';
import { Enum } from "../models/Enum";
import { RequestFunds } from "../models/RequestFunds";
import { RequestFundsInput } from "../models/RequestFundsInput";
import { SubscriptionOutPutDTO } from "../models/Subscription";
import { User } from "../models/User";
import {AdministrativeUnit, CountryData} from "../models/AdministrativeUnit";

@Injectable({
  providedIn: 'root'
})
export class PublicService {
  root = "";
  controller = "/public";
  showBackButton = new BehaviorSubject<boolean>(true);
  showBackToPaymentOptionButton = new BehaviorSubject<boolean>(true);
  customerCustomConfiguration = new BehaviorSubject<CustomerCustomConfiguration>(null);

  constructor(
    private http: HttpClient,
  ) {
    this.root = environment.apiUrl;
  }

  setShowBackToPaymentOptionButton(value: boolean) {
    this.showBackToPaymentOptionButton.next(value);
  }

  getShowBackToPaymentOptionButton(): Observable<boolean> {
    return this.showBackToPaymentOptionButton.asObservable();
  }

  setCustomerCustomConfiguration(value: CustomerCustomConfiguration) {
    this.customerCustomConfiguration.next(value);
  }

  getCustomerCustomConfiguration(): Observable<CustomerCustomConfiguration> {
    return this.customerCustomConfiguration.asObservable();
  }

  setShowBackButton(value: boolean) {
    this.showBackButton.next(value);
  }

  getShowBackButton(): Observable<boolean> {
    return this.showBackButton.asObservable();
  }

  public getCustomer(id:string): Observable<ApiResponse<Customer>>{
    return this.http.get<ApiResponse<Customer>>(this.root + this.controller + `/GetCustomer/${id}`);
  }

  public getCustomerCustomConfigurationFromApi(id:string): Observable<ApiResponse<CustomerCustomConfiguration>>{
    return this.http.get<ApiResponse<CustomerCustomConfiguration>>(this.root + this.controller + `/${id}/CustomerCustomConfiguration/`);
  }

  public getPeoplesTrustInteracUrl(id:string): Observable<ApiResponse<string>>{
    return this.http.get<ApiResponse<string>>(this.root + this.controller + `/GetInteracUrl/${id}`);
  }

  public getPeoplesTrustInteracStatus(id:string): Observable<ApiResponse<string>>{
    return this.http.get<ApiResponse<string>>(this.root + this.controller + `/GetInteracStatus/${id}`);
  }

  public getRequestFunds(id:string): Observable<ApiResponse<RequestFunds>>{
    return this.http.get<ApiResponse<RequestFunds>>(this.root + this.controller + `/GetRequestFunds/${id}`);
  }

  public addUser(entity:User): Observable<ApiResponse<CompleteTransactionOutputDTO>>{
    return this.http.post<ApiResponse<CompleteTransactionOutputDTO>>(this.root + this.controller + '/AddUser', entity);
  }

  public ingestFromAggregation(body: FundingSourceIngestFromAggregationInputDTO): Observable<ApiResponse<CompleteTransactionOutputDTO>>{
        return this.http.post<ApiResponse<CompleteTransactionOutputDTO>>(this.root + this.controller + '/IngestFromAggregation', body);
  }

  public getAggregationToken(input:FundingSourceIngestFromAggregationInputDTO): Observable<ApiResponse<string>>{
    return this.http.post<ApiResponse<string>>(this.root + this.controller + "/GetAggregationToken", input);
  }

  public addFundingSourceWithAggregation(input:FundingSourceIngestFromAggregationInputDTO): Observable<ApiResponse<BankAccountInformation>>{
    return this.http.post<ApiResponse<BankAccountInformation>>(this.root + this.controller + "/AddFundingSourceWithAggregation", input);
  }

  public getAccountType(): Observable<ApiResponse<Enum[]>>{
    return this.http.get<ApiResponse<Enum[]>>(this.root + this.controller + `/AccountType`);
  }

  public completeRequestFunds(body: RequestFundsInput): Observable<ApiResponse<CompleteTransactionOutputDTO>>{
    return this.http.post<ApiResponse<CompleteTransactionOutputDTO>>(this.root + this.controller + '/completeRequestFunds', body);
  }

  public getBusinessDayOfWeek(): Observable<ApiResponse<Enum[]>>{
    return this.http.get<ApiResponse<Enum[]>>(this.root + this.controller + `/BusinessDayOfWeek`);
  }

  public getCountries(): Observable<ApiResponse<AdministrativeUnit[]>> {
    return this.http.get<ApiResponse<AdministrativeUnit[]>>(this.root + this.controller + `/GetUserAcceptableCCCountries`);
  }

  public getCountry(countryCode:string): Observable<ApiResponse<CountryData>> {
    return this.http.get<ApiResponse<CountryData>>(this.root + this.controller + `/GetUserAcceptableCCCountries/`+countryCode);
  }

  public getCountriesByCustomer(customerId: string): Observable<ApiResponse<AdministrativeUnit[]>> {
    const url = `${this.root}${this.controller}/GetUserAcceptableCcCountriesByCustomer/${customerId}`;
    return this.http.get<ApiResponse<AdministrativeUnit[]>>(url);
  }
  
}
