import { Component, OnInit, ViewEncapsulation } from "@angular/core";

@Component({
    selector: "force-version-two",
    template: "",
    styleUrls: ["./force-version-two-styles.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ForceVersionTwoStylesComponent {
    constructor() {}
}
