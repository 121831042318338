import { Injectable } from "@angular/core";
import { ConnectErrorCode } from "../models/ConnectErrorCode";
import { GetTokenInformationOutput } from "../models/GetTokenInformationOutput";
import { TokenService } from "./token.service";
import { EventListenerStep } from "../models/EventListenerSteps";
import { trackActivity } from "./track.service";
import { OptionalQueryParams } from "../pages/connect/types";

@Injectable()
export class EventListenerService {
    getTokenInformationOutput: GetTokenInformationOutput | undefined;

    constructor(private tokenService: TokenService) {}

    resize(height: string, width?: string) {
        var data = {
            step: "RESIZE",
            height: height,
            width: width,
        };

        window.top.postMessage(data, "*");
    }

    closePopup() {
        this.getTokenInformationOutput =
            this.tokenService.getTokenInformationFromService();
        this.tokenService
            .removeTokenInRedis(this.getTokenInformationOutput?.Token)
            .subscribe(
                (data) => {
                    var windowData = {
                        step: "CLOSE",
                    };

                    window.top.postMessage(windowData, "*");
                },
                (error) => {
                    if (error.status == 400) {
                        var data = {
                            step: "CLOSE",
                        };

                        window.top.postMessage(data, "*");
                    }
                }
            );
    }

    stepChange(step: EventListenerStep) {
        this.getTokenInformationOutput =
            this.tokenService.getTokenInformationFromService();
        this.tokenService
            .removeTokenInRedis(this.getTokenInformationOutput?.Token)
            .subscribe(
                (data) => {
                    var windowData = {
                        step: step.toString().toUpperCase(),
                    };

                    window.top.postMessage(windowData, "*");
                },
                (error) => {
                    if (error.status == 400) {
                        var data = {
                            step: step.toString().toUpperCase(),
                        };

                        window.top.postMessage(data, "*");
                    }
                }
            );
    }

    success(
        invoiceId?: any,
        userId?: any,
        transactionId?: any,
        subscriptionId?: any,
        origin?: any,
        clientUserId?: any,
        extraField1?: any,
        extraField2?: any,
        paymentInstrumentId?: any,
        requestId?: any
    ) {
        var data = {
            step: "SUCCESS",
            invoiceId: invoiceId,
            userId: userId,
            clientUserId: clientUserId,
            extraField1: extraField1,
            extraField2: extraField2,
            transactionId: transactionId,
            subscriptionId: subscriptionId,
            origin: origin,
            paymentInstrumentId: paymentInstrumentId,
            requestId: requestId,
        };

        this.trackActivity(
            EventListenerStep.CONNECTCOMPLETED,
            {
                userid: userId,
                subscriptionId: subscriptionId,
                request_Id: requestId,
                transactionId: transactionId
            }
        );

        window.top.postMessage(data, "*");
    }

    error(
        error: ConnectErrorCode,
        errorMessage: string = undefined,
        requestId?: any) {
        var data = {
            step: "ERROR",
            error,
            errorMessage,
        };

        this.trackActivity(
            EventListenerStep.CONNECTFAILED,
            {
                error_text: `${error} ${errorMessage}`,
                request_Id: requestId,
            }
        );

        window.top.postMessage(data, "*");
    }

    pageLoaded() {
        var data = {
            step: "PAGE-LOAD",
        };

        window.top.postMessage(data, "*");
    }

    trackActivity(step: EventListenerStep, data: any) {
        var params = data?.optionalQueryParams as OptionalQueryParams;
        let dataToTrack = {
            institution_id: data?.institution,
            security_question_type: data?.securityquestiontype,
            account_id: data?.selectedaccountid,
            error_message: undefined,
            subscription_id: data?.subscriptionId,
            request_Id: data?.requestId,
            transaction_id: data?.transactionId,
            error_text: data?.error_text,
            card_id: data?.cardid,
            extrafield1: data?.extrafield1,
            extrafield2: data?.extrafield2,
            user_id: data?.userid,
            allow_eft: params?.allowEft,
            allow_interac: params?.allowInterac,
            allow_visadirect: params?.allowVisaDirect,
            allow_creditcard: params?.allowCreditCard,
            allow_ach: params?.allowAch,
            force_connectv2: data?.forceConnectV2,
            display_terms_and_condition: params?.displayTermsAndCondition,
            display_terms_and_conditions: params?.displayTermsAndConditions,
            display_details_column: params?.displayDetailsColumn,
            display_zumrails_logo: params?.displayZumRailsLogo,
            display_phone_number: params?.displayPhoneNumber,
            display_full_name: params?.displayFullName,
            display_account_type: params?.displayAccountType,
            hide_shipping_address: params?.hideShippingAddress,
            hide_back_button: params?.hideBackButton,
            hide_rails_name: params?.hideRailsName,
            hide_first_name: params?.hideFirstName,
            hide_last_name: params?.hideLastName,
            hide_full_name: params?.hideFullName,
            hide_email: params?.hideEmail,
            hide_credit_card_address: params?.hideCreditCardAddress,
            language: params?.language,
            background_Color: params?.backgroundColor,
            foreground_Color: params?.foregroundColor,
            disable_PreFilled_Fields: params?.disablePreFilledFields,
            url_version: this.getVersionFromUrl(data?.router?.url),
            payment_method: data?.paymentMethod,
            account_type: data?.accountType,
            product_id: data?.productId,
            product_name: data?.productName,
            price_id: data?.priceId,
            price_name: data?.priceName,
            price_recurring: data?.priceRecurring,
            billing_day: data?.billingDay,
            billing_day_of_month: data?.billingDayOfMonth,
            billing_month_of_year: data?.billingMonthOfYear,
            billing_day_of_year: data?.billingDayOfYear,
            last_day_of_month: data?.lastDayOfMonth,
            quantity: data?.quantity
        }
        trackActivity(step, dataToTrack)
    }

    private getVersionFromUrl(url: string | undefined): string | undefined{
        if(!url) return undefined;

        const v2Marker = "v2";
        const urlSegments = url.split('/');
        const urlVersionSegment = urlSegments[3]?.slice(0, v2Marker.length);

        return !urlVersionSegment && urlVersionSegment != v2Marker
        ? "V1"
        : "V2"
    }
}
