import {Component, Input} from '@angular/core';

@Component({
    selector: 'payment-loader-v2',
    templateUrl: './payment-loader-v2.component.html',
    styleUrls: ['./payment-loader-v2.component.scss']
})
export class PaymentLoaderV2 {
    @Input() label = "";

    public loading = true;
    public showDefaultText = false;
}
